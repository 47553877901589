import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function mustMatch(control: UntypedFormControl, matchingControl: UntypedFormControl) {
    return (formGroup: UntypedFormGroup) => {

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return;
        }

        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({mustMatch: true});
        } else {
            matchingControl.setErrors(null);
        }
    };
}
