import {Entity} from '../entity.model';
import {Ship} from '../ship/ship.model';
import {User} from '../user/user.model';
import {TripTag} from './trip-tag.model';
import {TripType} from './trip-type.model';
import {TripCity} from './trip-city.model';
import {TripData} from './trip-data.model';
import {TripStatus} from './trip-status.model';
import {TripSystem} from './trip-system.model';
import {IConvertable} from '../interfaces/convertable';
import {FolderLink} from '../folder-link/folder-link.model';
import {DestinationArea} from '../travel/destination-area.model';
import {AdvertisingSpot} from '../advertising-spot/advertising-spot.model';
import {ServiceProvider} from '../service-provider/service-provider.model';
import {MarketingBriefing} from '../marketing-briefing/marketing-briefing.model';
import {ServiceProviderOption} from '../service-provider-option/service-provider-option.model';
import {dateStringToLocaleDateString} from '../../helper/date-utils';

export class Trip extends Entity implements IConvertable<TripData> {
    startDate: string;
    endDate: string;
    title: string;
    code: string;
    organizerNumber: string;
    contingentMin: number;
    contingentMax: number;
    depositInPercent: number;
    daysPriorForFinalpayment: number;
    note: string;
    createdAt: string;
    updatedAt: string;
    serviceProviderOptions: ServiceProviderOption[];
    tripStatus: TripStatus;
    responsibleUser: User;
    tripType: TripType;
    ship: Ship;
    fromCity: TripCity;
    toCity: TripCity;
    tags: TripTag[];
    serviceProvider: ServiceProvider;
    destinationAreas: DestinationArea[];
    marketingBriefing: MarketingBriefing;
    nextOptionDate: string;
    advertisingSpots: AdvertisingSpot[];
    enabled: boolean;
    tripSystem: TripSystem;
    pax: string;
    serialAppointment: boolean;
    paymentMethod: PaymentMethod;
    hasTravelCompanionOption: boolean;
    folderLinks: FolderLink[];
    imageFolderLinks: FolderLink[];
    /** Marks a legacy trip that will not support all newer features, e.g. trip code generation. */
    old: boolean;
    tripExistsInTm: boolean;
    nextPublicationDate: string;
    label?: string;

    deserialize(input: any): this {
        super.deserialize(input);

        this.label = this.initLabel();

        if (input.responsibleUser) {
            this.responsibleUser = new User().deserialize(input.responsibleUser);
        }

        return this;
    }

    convert(): TripData {
        const dateOptions: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        };
        let currentServiceProviderOption = this.serviceProviderOptions[0];
        for (const serviceProviderOption of this.serviceProviderOptions) {
            const currentDate = new Date();
            const optionDate = new Date(serviceProviderOption.optionDate);
            if (optionDate > currentDate && serviceProviderOption < currentServiceProviderOption) {
                currentServiceProviderOption = serviceProviderOption;
            }
        }
        return new TripData().deserialize(
            {
                id: this.id,
                enabled: this.enabled,
                serialAppointment: this.serialAppointment,
                hasTravelCompanionOption: this.hasTravelCompanionOption,
                startDate: new Date(this.startDate),
                endDate: new Date(this.endDate),
                nextOptionDate: this.nextOptionDate ?
                    new Date(this.nextOptionDate).toLocaleString('de-DE', dateOptions) : '-',
                title: this.title,
                code: this.code,
                tripSystem: this.tripSystem ? this.tripSystem.name : '-',
                organizerNumber: this.organizerNumber,
                min: this.contingentMin,
                max: this.contingentMax,
                depositInPercent: this.depositInPercent,
                daysPriorForFinalpayment: this.daysPriorForFinalpayment,
                note: this.note,
                tripStatus: this.tripStatus,
                tripType: this.tripType,
                paymentMethod: this.paymentMethod,
                ship: this.ship,
                toCity: this.toCity,
                fromCity: this.fromCity,
                nextPublicationDate: this.nextPublicationDate ?
                    new Date(this.nextPublicationDate).toLocaleString('de-DE', dateOptions) : '-',
                advertisingSpotCount: this.advertisingSpots ? this.advertisingSpots.length : 0,
                ships: this.serviceProviderOptions
                    .filter(provider => provider.serviceProviderCategory
                        && provider.serviceProviderCategory.name === 'Schiff')
                    .map(provider => provider.serviceProvider.shortCode),
                buses: this.serviceProviderOptions
                    .filter(provider => provider.serviceProviderCategory
                        && provider.serviceProviderCategory.name === 'Bus')
                    .map(provider => provider.serviceProvider.ltCode),
                planes: this.serviceProviderOptions
                    .filter(provider => provider.serviceProviderCategory
                        && provider.serviceProviderCategory.name === 'Flug')
                    .map(provider => provider.serviceProvider.ltCode),
                trains: this.serviceProviderOptions
                    .filter(provider => provider.serviceProviderCategory
                        && provider.serviceProviderCategory.name === 'Bahn')
                    .map(provider => provider.serviceProvider.ltCode),
                travelCompanions: this.serviceProviderOptions
                    .filter(providerOption => providerOption.serviceProviderCategory
                        && providerOption.serviceProviderCategory.name === 'Reisebegleiter'
                        && providerOption.travelCompanion)
                    .map(providerOption =>
                        `${providerOption.travelCompanion.firstName} ${providerOption.travelCompanion.lastName}`),
                serviceProvider: this.serviceProvider,
                destinationAreas: this.destinationAreas.map((d: DestinationArea) => d.name),
                serviceProviderOption: currentServiceProviderOption,
                responsibleUser: this.responsibleUser,
                tags: this.tags.map((t: TripTag) => t.name),
                old: this.old
            }
        );
    }

    private initLabel(): string {
        let label = this.title;

        if (this.startDate) {
            label += ` von ${dateStringToLocaleDateString(this.startDate)}`;
        }
        if (this.endDate) {
            label += ` bis ${dateStringToLocaleDateString(this.endDate)}`;
        }
        if (this.code) {
            label += ` (${this.code})`;
        }

        return label;
    }
}


export enum PaymentMethod {
    agenturinkasso = 'Agenturinkasso',
    direktinkasso = 'Direktinkasso'
}
