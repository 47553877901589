import {UserService} from '../../../service/user/user.service';
import {UserClaim} from '../../../models/user/user-claims.model';
import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[appAuth]'
})
export class AuthDirective {
    private authMode: 'or' | 'and' = 'or';
    private hidden: boolean;
    private neededClaims: UserClaim[];

    constructor(private userService: UserService,
                private element: ElementRef,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
    }

    @Input('appAuth')
    set claims(value: UserClaim[]) {
        this.neededClaims = value;
        this.viewContainer.clear();
        this.updateView();
    }

    @Input()
    set appAuthAuthMode(value: 'or' | 'and') {
        this.authMode = value;
        this.viewContainer.clear();
        this.updateView();
    }

    @Input()
    set appAuthHidden(value: boolean) {
        this.hidden = value;
        this.viewContainer.clear();
        this.updateView();
    }

    private updateView() {
        if ((!this.neededClaims || this.checkClaim()) && !this.hidden) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    private checkClaim(): boolean {
        return this.authMode === 'or' ?
            this.neededClaims.some(c => this.userService.hasClaim(c)) :
            this.neededClaims.every(c => this.userService.hasClaim(c));
    }
}
