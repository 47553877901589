export class AppRoutes {

    public static readonly user = {
        fullPath: '/app/user',
        path: 'user',
        team: {
            fullPath: '/app/user/team',
            path: 'team',
            new: {
                fullPath: '/app/user/team/new',
                path: 'new'
            }
        },
        myProfile: {
            fullPath: '/app/user/my-profile',
            path: 'my-profile'
        },
        new: {
            fullPath: '/app/user/new',
            path: 'new'
        }
    };

    public static readonly settings = {
        fullPath: '/app/settings',
        path: 'settings',
        serviceProvider: {
            fullPath: '/app/settings/service-providers',
            path: 'service-providers',
            new: {
                fullPath: '/app/settings/service-providers/new',
                path: 'new'
            }
        },
        destinationAreas: {
            fullPath: '/app/settings/destination-areas',
            path: 'destination-areas',
            new: {
                fullPath: '/app/settings/destination-areas/new',
                path: 'new'
            }
        },
        ships: {
            fullPath: '/app/settings/ships',
            path: 'ships',
            new: {
                fullPath: '/app/settings/ships/new',
                path: 'new'
            }
        },
        tags: {
            fullPath: '/app/settings/tags',
            path: 'tags',
            new: {
                fullPath: '/app/settings/tags/new',
                path: 'new'
            }
        },
        listViews: {
            fullPath: '/app/settings/list-views',
            path: 'list-views'
        },
        travelCompanions: {
            fullPath: '/app/settings/travel-companions',
            path: 'travel-companions',
            new: {
                fullPath: '/app/settings/travel-companions/new',
                path: 'new'
            }
        },
        formats: {
            fullPath: '/app/settings/formats',
            path: 'formats',
            new: {
                fullPath: '/app/settings/formats/new',
                path: 'new'
            }
        },
        tasks: {
            fullPath: '/app/settings/task-types',
            path: 'task-types',
            new: {
                fullPath: '/app/settings/task-types/new',
                path: 'new'
            }
        },
        media: {
            fullPath: '/app/settings/media',
            path: 'media',
            new: {
                fullPath: '/app/settings/media/new',
                path: 'new'
            }
        }
    };

    public static readonly app = {
        fullPath: 'app',
        user: AppRoutes.user,
        settings: AppRoutes.settings,
        trips: {
            fullPath: '/app/trips',
            path: 'trips',
            new: {
                fullPath: '/app/trips/new',
                path: 'new'
            },
            bulk: {
                fullPath: '/app/trips/bulk',
                path: 'bulk'
            },
            copy: {
                fullPath: '/app/trips/copy/:id',
                path: 'copy/:id'
            }
        },
        tasks: {
            fullPath: '/app/tasks',
            path: 'tasks',
            new: {
                fullPath: '/app/tasks/new',
                path: 'new'
            },
            details: {
                fullPath: '/app/tasks/:id',
                path: ':id'
            }
        },
        insertions: {
            fullPath: '/app/insertions',
            path: 'insertions',
            new: {
                fullPath: '/app/insertions/new',
                path: 'new'
            },
            bulk: {
                fullPath: '/app/insertions/bulk',
                path: 'bulk'
            },
            copy: {
                fullPath: '/app/insertions/copy/:id',
                path: 'copy/:id'
            },
            advertisingSpot: {
                path: 'advertising-spot/:id',
                fullPath: '/app/insertions/advertising-spot/:id',
            },
            details: {
                fullPath: '/app/insertions/:id',
                path: ':id'
            }
        },
        advertisingMaterial: {
            fullPath: '/app/advertising-material',
            path: 'advertising-material',
            new: {
                fullPath: '/app/advertising-material/new',
                path: 'new'
            },
            advertisingSpot: {
                path: 'advertising-spot/:id',
                fullPath: '/app/advertising-material/advertising-spot/:id',
            },
        },
        login: {
            fullPath: '/app/login',
            path: 'login'
        },
        forgotPassword: {
            fullPath: '/app/forgot-password',
            path: 'forgot-password'
        },
        setPassword: {
            fullPath: '/app/set-password',
            path: 'set-password'
        }
    };
}
