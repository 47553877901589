<div class="icon_wrapper"
    [matMenuTriggerFor]="menu">
    <div [matBadge]="'' +  notifications.length"
         [matBadgeHidden]="notifications.length < 1"
         matBadgeSize="small"
         matBadgeColor="warn">
        <fa-icon
            class="topbar_icon"
            [icon]="faBell"
            size="lg"
        ></fa-icon>
    </div>
</div>

<mat-menu class="notifications" #menu>
    <ng-container *ngIf="notifications.length < 1">
        <div empty>
            <fa-icon [icon]="faEnvelope" size="2x"></fa-icon>
            <span mat-menu-item>Keine Benachrichtigungen</span>
        </div>
    </ng-container>
    <ng-container *ngFor="let notification of notifications; let i = index">
        <div mat-menu-item [ngClass]="{'no-cursor': !notification.task}">
            <app-notification-item [notification]="notification"></app-notification-item>
            <mat-divider *ngIf="i < notifications.length - 1"></mat-divider>
        </div>
    </ng-container>
</mat-menu>
