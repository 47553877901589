import * as moment from 'moment';
import {Config} from '../constants/config.constants';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function toHtmlString(date: Date, includeTime = false): string {
    if (includeTime) {
        return moment(date).format(Config.momentDatetimeFormat);
    }
    return moment(date).format(Config.momentDateFormat);
}
