<div class="background">
    <mat-card appearance="outlined" class="card">
        <form
            (ngSubmit)="onSubmit(setPasswordForm.value)"
            [formGroup]="setPasswordForm"
        >
            <div
                class="container"
            >
                <h2>Passwort setzen</h2>

                <!--@angular-material-extensions/password-strength's main component-->
                <mat-password-strength #passwordComponent
                                       (onStrengthChanged)="onStrengthChanged($event)"
                                       [enableLowerCaseLetterRule]="false"
                                       [enableUpperCaseLetterRule]="false"
                                       [password]="password.value">
                </mat-password-strength>
                <!--Password's strength info-->
                <mat-password-strength-info
                    [passwordComponent]="passwordComponent"
                    digitsCriteriaMsg="enthält mindestens eine Ziffer"
                    minCharsCriteriaMsg="enthält mindestens {{minNumberCharacters}} Zeichen"
                    specialCharsCriteriaMsg="enthält mindestens ein Sonderzeichen">
                </mat-password-strength-info>

                <mat-form-field>
                    <input
                        [formControl]="password"
                        matInput
                        placeholder="Passwort"
                        required
                        type="password">
                    <mat-hint align="end" aria-live="polite">
                        {{password.value.length}} / {{minNumberCharacters}}
                    </mat-hint>
                    <mat-error *ngIf="password.invalid">{{getErrorMessage(password)}}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input
                        [formControl]="confirmPassword"
                        matInput
                        placeholder="Passwort bestätigen"
                        required
                        type="password">
                    <mat-error *ngIf="confirmPassword.invalid">{{getConfirmPasswordError()}}</mat-error>
                </mat-form-field>

                <div class="buttons">
                    <button
                        [disabled]="setPasswordForm.touched && !setPasswordForm.valid"
                        class="submit_button"
                        color="primary"
                        mat-raised-button
                        type="submit">
                        Ok
                    </button>
                </div>
            </div>
        </form>
    </mat-card>
</div>
