import {IDeserializable} from './interfaces/deserializable';

export class Entity implements IDeserializable{
    id?: number;
    deserialize(input: any): this {
        Object.assign(this as any, input);

        return this;
    }
}
