import {Entity} from '../entity.model';
import {User} from '../user/user.model';
import {Task} from '../task/task.model';
import {TaskComment} from './task-comment.model';

export class Notification extends Entity {
    public text: string;
    public createdAt: Date;
    public task: Task;
    public taskComment: TaskComment;
    public triggeringUser: User;
}
