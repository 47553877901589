import {NgModule} from '@angular/core';
import {UserGuard} from '../guard/user.guard';
import {ClaimGuard} from '../guard/claim.guard';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {MainComponent} from './main/main/main.component';
import {AppRoutes} from '../constants/navigation.constants';
import {SetPasswordComponent} from './login/set-password/set-password.component';
import {ForgotPasswordComponent} from './login/forgot-password/forgot-password.component';

const routes: Routes = [
    {
        path: AppRoutes.app.fullPath,
        component: MainComponent,
        canActivateChild: [UserGuard],
        resolve: {claims: ClaimGuard},
        children: [
            {
                path: AppRoutes.app.user.path,
                loadChildren: () => import('./user/user.module').then(mod => mod.UserModule)
            },
            {
                // Settings
                path: AppRoutes.app.settings.path,
                children: [
                    {
                        // Serviceprovider
                        path: AppRoutes.app.settings.serviceProvider.path,
                        loadChildren: () =>
                            import('./service-provider/service-provider.module').then(mod => mod.ServiceProviderModule)
                    },
                    {
                        // Destination Areas
                        path: AppRoutes.app.settings.destinationAreas.path,
                        loadChildren: () =>
                            import('./destination-area/destination-area.module').then(mod => mod.DestinationAreaModule)
                    },
                    {
                        // Ship
                        path: AppRoutes.app.settings.ships.path,
                        loadChildren: () =>
                            import('./ship/ship.module').then(mod => mod.ShipModule)
                    },
                    {
                        // Tags
                        path: AppRoutes.app.settings.tags.path,
                        loadChildren: () => import('./tag/tag.module').then(mod => mod.TagModule)
                    },
                    {
                        // List Views
                        path: AppRoutes.app.settings.listViews.path,
                        loadChildren: () => import('./list-view/list-view.module').then(mod => mod.ListViewModule)
                    },
                    {
                        // travel-companions
                        path: AppRoutes.app.settings.travelCompanions.path,
                        loadChildren: () =>
                            import('./travel-companion/travel-companion.module').then(mod => mod.TravelCompanionModule)
                    },
                    {
                        // formats
                        path: AppRoutes.app.settings.formats.path,
                        loadChildren: () =>
                            import('./medium-format/medium-format.module').then(mod => mod.MediumFormatModule)
                    },
                    {
                        // media
                        path: AppRoutes.app.settings.media.path,
                        loadChildren: () =>
                            import('./medium/medium.module').then(mod => mod.MediumModule)
                    },
                    {
                        // task-types
                        path: AppRoutes.app.settings.tasks.path,
                        loadChildren: () => import('./task-type/task-type.module').then(mod => mod.TaskTypeModule)
                    }
                ]
            },
            // Trips
            {
                path: AppRoutes.app.trips.path,
                loadChildren: () => import('./trip/trip.module').then(mod => mod.TripModule)
            },
            // Insertions
            {
                path: AppRoutes.app.insertions.path,
                loadChildren: () => import('./insertion/insertion.module').then(mod => mod.InsertionModule)
            },
            // AdvertisingMaterials
            {
                path: AppRoutes.app.advertisingMaterial.path,
                loadChildren: () => import('./advertising-material/advertising-material.module')
                    .then(mod => mod.AdvertisingMaterialModule)
            },
            // Tasks
            {
                path: AppRoutes.app.tasks.path,
                loadChildren: () => import('./task/task.module').then(mod => mod.TaskModule)
            }
        ]
    },
    {path: `${AppRoutes.app.fullPath}/${AppRoutes.app.login.path}`, component: LoginComponent},
    {path: `${AppRoutes.app.fullPath}/${AppRoutes.app.forgotPassword.path}`, component: ForgotPasswordComponent},
    {path: `${AppRoutes.app.fullPath}/${AppRoutes.app.setPassword.path}/:token`, component: SetPasswordComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
