export class Config {
    static readonly itemsPerPage = 50;
    static readonly loadingDelay = 500;
    static readonly delayForDropdownSearch = 500;
    static readonly debounceForDropdownSearch = 200;
    static readonly singleDigitYearDateFormat = 'y-MM-dd';
    static readonly momentDateFormat = 'YYYY-MM-DD';
    static readonly momentDatetimeFormat = 'YYYY-MM-DD HH:mm:ss';
    static readonly dayOfWeekFormat = 'dddd, DD.MM.YYYY';
    static readonly dayOfWeekShortFormat = 'dd, DD.MM.YYYY';
    static readonly dateFormat = 'dd.MM.y';
    static readonly dateFormatWeekday = 'EEEEEE dd.MM.y';
    static readonly dateTimeFormat = 'dd.MM.y (HH:mm)';
    static readonly numberPattern = '^[0-9]*([.,][0-9]+)?$';
    static readonly pricePattern = '^[0-9]*([.,][0-9]{1,2})?$';
    static readonly wholeNumberPattern = '^[0-9]*$';
    static readonly passwordPattern = '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*\\W).*$';
    static readonly dateFormatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    static readonly locale = 'de-DE';
}
