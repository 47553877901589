import {MenuValues} from './menu.values';
import {Component, OnInit} from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
    menuItems = MenuValues.menu;
    faAngleDown = faAngleDown;

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.router.events.subscribe(event => this.routeUpdated(event));
        this.checkRoute(this.router.url);
    }

    routeUpdated(routingEvent: Event) {
        if (routingEvent instanceof NavigationEnd) {
            this.checkRoute(routingEvent.url);
        }
    }

    checkRoute(route: string) {
        this.menuItems.forEach(menuItem => {
            menuItem.active = route.startsWith(menuItem.link);
        });
    }
}
