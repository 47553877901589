<form [formGroup]="feedbackForm"
      cdkDrag
      cdkDragRootElement=".cdk-overlay-pane">
    <div mat-dialog-title
         cdkDragHandle>
        <h2>Werbemittel-Feedback</h2>
        <div class="topRightButtons">
            <fa-icon *ngIf="advSpot || task" class="externalLinkIcon" [icon]="faExternalLinkAlt" (click)="openInNewTab()"></fa-icon>
            <button mat-icon-button color="primary"
                    type="button"
                    (click)="dialogRef.close()">
                <fa-icon [icon]="faTimes"></fa-icon>
            </button>
        </div>
    </div>
    <div content>
        <div form>
            <mat-form-field appearance="outline">
                <mat-label>Betreff</mat-label>
                <input
                    [formControl]="feedbackForm.controls['subject']"
                    readonly
                    required
                    matInput
                    autocomplete="off"
                >
                <mat-error *ngIf="feedbackForm.controls['subject'].invalid">
                    {{ getErrorMessage(feedbackForm.controls['subject']) }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Beschreibung</mat-label>
                <textarea
                    [formControl]="feedbackForm.controls['description']"
                    readonly
                    required
                    matInput
                    autocomplete="off">
                </textarea>
                <mat-error *ngIf="feedbackForm.controls['description'].invalid">
                    {{ getErrorMessage(feedbackForm.controls['description']) }}
                </mat-error>
            </mat-form-field>
            <div dates>
                <mat-form-field appearance="outline">
                    <mat-label>Startdatum</mat-label>
                    <input
                        matInput
                        [matDatepicker]="startDate"
                        [formControl]="feedbackForm.controls['startDate']"
                        readonly
                        autocomplete="off"
                    >
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker disabled #startDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div dates>
                <mat-form-field appearance="outline">
                    <mat-label>Deadline</mat-label>
                    <input
                        matInput
                        required
                        [matDatepicker]="deadline"
                        [formControl]="feedbackForm.controls['deadline']"
                        autocomplete="off"
                    >
                    <mat-datepicker-toggle matSuffix [for]="deadline"></mat-datepicker-toggle>
                    <mat-datepicker #deadline></mat-datepicker>
                    <mat-error *ngIf="feedbackForm.controls['deadline'].invalid">
                        {{ getErrorMessage(feedbackForm.controls['deadline']) }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Deadline-Zeit</mat-label>
                    <input
                        matInput
                        [formControl]="feedbackForm.controls['deadlineTime']"
                        type="time"
                    >
                    <mat-error *ngIf="feedbackForm.controls['deadlineTime'].invalid">{{getErrorMessage(feedbackForm.controls['deadlineTime'])}}</mat-error>
                </mat-form-field>
            </div>

            <div users>
                <app-autocomplete-select [innerFormControl]="feedbackForm.controls['createdBy']"
                                         [readonly]="true"
                                         [allOptions]="[feedbackForm.controls['createdBy'].value]"
                                         [getErrorMessageForFormControl]="getErrorMessage"
                                         [buildDisplayString]="getUserFullName"
                                         label="Erstellt von">
                </app-autocomplete-select>
                <app-autocomplete-select [innerFormControl]="feedbackForm.controls['reviewer']"
                                         [required]="true"
                                         [allOptions]="users"
                                         [searchKeys]="['firstName', 'lastName']"
                                         [getErrorMessageForFormControl]="getErrorMessage"
                                         [buildDisplayString]="getUserFullName"
                                         label="Zuweisen an">
                </app-autocomplete-select>
            </div>

            <ng-container *ngIf="data.advMatTaskTypeId === advMatTaskTypes.default">
                <h4>Reisen:</h4>
                <!-- Trip Links -->
                <ng-container *ngFor="let trip of advSpot?.trips">
                    <a target='_blank' [routerLink]="['app/trips', trip.id]">
                        <div>
                            {{ getTripLabel(trip) }}
                            <fa-icon [icon]="faExternalLinkAlt"></fa-icon>
                        </div>
                    </a>
                </ng-container>
                <mat-divider></mat-divider>
            </ng-container>
            <a target='_blank' [routerLink]="['app/advertising-material', advMat?.id]">
                <div>
                    Werbemittel
                    <fa-icon [icon]="faExternalLinkAlt"></fa-icon>
                </div>
            </a>
            <ng-container *ngFor="let folderLink of folderLinkArrayFC.controls; let i = index">
                <div link>
                    <mat-form-field appearance="outline">
                        <mat-label>Link</mat-label>
                        <input [formControl]="folderLink.controls.path"
                               matInput
                               [required]="folderLinkArrayFC.length > i + 1 && !folderLink.controls.path.value"
                               autocomplete="off"
                        >
                        <mat-error *ngIf="folderLink.controls.path.invalid">
                            {{ getErrorMessage(folderLink.controls.path) }}
                        </mat-error>
                        <a matSuffix
                           *ngIf="folderLink.controls.path.value"
                           href="file://{{folderLink.controls.path.value}}">
                            <fa-icon [icon]="faExternalLinkAlt"></fa-icon>
                        </a>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Version</mat-label>
                        <input
                            [formControl]="folderLink.controls.version"
                            readonly
                            matInput
                            autocomplete="off"
                        >
                    </mat-form-field>
                </div>
            </ng-container>
            <app-task-attachment
                *ngFor="let attachment of attachmentFC.value; let i = index"
                [control]="attachmentFC"
                [attachmentData]="attachment"
                [attachmentIndex]="i"
                [deletedFC]="deletedAttachments"
            >
            </app-task-attachment>
            <button
                type="button"
                color="primary"
                mat-button
                (click)="fileInput.click()">
                Anlage hinzufügen
            </button>
            <input #fileInput
                   hidden
                   type="file"
                   accept="/*"
                   (change)="uploadAttachment(fileInput)"
                   multiple
            >

            <!-- askFeedback -->
            <ng-container *ngIf="displayFeedbackButton()">
                <button mat-raised-button class="color_orange"
                        type="submit"
                        (click)="sendTask()"
                        [disabled]="submitting">
                    {{strings.askFeedback[type]}}
                </button>
            </ng-container>

            <!-- approve -->
            <ng-container *ngIf="displayApproveButton()">
                <p>{{strings.approveDescription}}</p>
                <button mat-raised-button class="color_green"
                        type="submit"
                        (click)="performAction(actions.approve)"
                        [disabled]="submitting">
                    {{strings.approve.replace('$n', '' + task.version)}}</button>
            </ng-container>

            <!-- giveFeedback -->
            <ng-container *ngIf="displayFeedbackAndReviewButton()">
                <p>{{strings.giveFeedbackDescription}}</p>
                <button mat-raised-button class="color_orange"
                        type="submit"
                        (click)="performAction(actions.giveFeedback)"
                        [disabled]="submitting">
                    {{strings.giveFeedback}}</button>
            </ng-container>

            <!-- giveFeedbackAndApprove -->
            <ng-container *ngIf="displayAppliedFeedbackAndApproveButton()">
                <p>{{strings.giveFeedbackAndApproveDescription}}</p>
                <button mat-raised-button
                        color="warn"
                        type="submit"
                        (click)="performAction(actions.giveFeedbackAndApprove)"
                        [disabled]="submitting">
                    {{strings.giveFeedbackAndApprove}}</button>
            </ng-container>

            <!-- restartReview -->
            <ng-container *ngIf="!displayFeedbackAndReviewButton() && !displayApproveButton() && !displayFeedbackButton()">
                <button mat-raised-button class="color_green"
                        type="submit"
                        (click)="performAction(actions.restartReview)"
                        [disabled]="submitting">
                    {{strings.restartReview}}</button>
            </ng-container>

            <!-- finishReview -->
            <ng-container *ngIf="displayFinishButton()">
                <button mat-raised-button class="color_green finish_review"
                        type="submit"
                        (click)="performAction(actions.finishReview)"
                        [disabled]="submitting">
                    {{strings.finishReview}}
                </button>
            </ng-container>

            <!-- Review Speichern -->
            <button color="primary"
                    class="saveReview"
                    mat-raised-button type="submit"
                    (click)="saveReview()"
                    [disabled]="submitting">
                Speichern
            </button>

        </div>
        <div comments>
            <app-comment [comments]="comments"
                         (commentsChange)="commentsChanged($event)"></app-comment>
        </div>
    </div>
</form>
