<div container>
    <div avatar>
        <div class="avatar">
            <img *ngIf="getAvatar(); else name"
                 [src]="getAvatar()"
                 class="avatar"
                 alt="{{ comment.createdBy?.firstName }} {{ comment.createdBy?.lastName }}">
            <ng-template #name>
                <div>{{ comment.createdBy?.firstName }} {{ comment.createdBy?.lastName }}</div>
            </ng-template>
        </div>
    </div>
    <div message>
        <div title>
            <span name>{{comment.createdBy?.firstName}} {{comment.createdBy?.lastName}}</span>
            <span date>{{comment.createdAt | date: 'dd.MM.yy HH:mm:ss'}}</span>
        </div>
        <div text>{{comment.text}}</div>
    </div>
</div>
