import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Entity} from '../models/entity.model';

/**
 * @todo refactoring: clean types
 */
export abstract class CrudService<T extends Entity> {

    protected constructor(protected readonly http: HttpClient,
                          protected readonly route: string) {
    }

    public get(id: number): Observable<T> {
        const route = `${this.route}/${id}`;
        return this.http.get<T>(route).pipe(
            map(obj => this.newInstance(obj))
        );
    }

    public getAll(query?: string): Observable<T[]> {
        let route = this.route;
        if (query) {
            route += `?${query}`;
        }
        return this.http.get<T[]>(route).pipe(
            map(response => response.map(obj => this.newInstance(obj)))
        );
    }

    public filterAll(query?: string, filterBody?: any): Observable<T[]> {
        let route = `${this.route}/filter`;
        if (query) {
            route += `?${query}`;
        }
        return this.http.post<T[]>(route, filterBody).pipe(
            map(response => response.map(obj => this.newInstance(obj)))
        );
    }

    public post(body: T): Observable<T> {
        return this.http.post<T>(this.route, body).pipe(
            map(obj => this.newInstance(obj))
        );
    }

    public put(body: T): Observable<T> {
        const route = `${this.route}/${body.id}`;
        return this.http.put(route, body).pipe(
            map(obj => this.newInstance(obj))
        );
    }

    /**
     * @todo refactoring - use Partial<T> as type for body here
     */
    public patch(body: any, id: number): Observable<T> {
        const route = `${this.route}/${id}`;
        return this.http.patch(route, body).pipe(
            map(obj => this.newInstance(obj))
        );
    }

    public patch204(body: Record<string, unknown>, id: number): Observable<void> {
        const route = `${this.route}/${id}`;
        return this.http.patch<void>(route, body);
    }

    public delete(id: number): Observable<void> {
        const route = `${this.route}/${id}`;
        return this.http.delete<void>(route);
    }

    protected abstract newInstance(obj: any): T;
}


