import * as moment from 'moment';
import {Trip} from '../models/trip/trip.model';
import {ServiceProvider} from '../models/service-provider/service-provider.model';
import {DestinationArea} from '../models/travel/destination-area.model';
import {Ship} from '../models/ship/ship.model';
import {TripConstants} from '../constants/trip.constants';

export class TripHelper {
    public static getLabel(trip: Trip): string {
        let labelString = trip.title +
            ' von ' + moment(trip.startDate).format('DD.MM.YYYY') +
            ' bis ' + moment(trip.endDate).format('DD.MM.YYYY');

        if (trip.code) {
            labelString = labelString + ' (' + trip.code + ')';
        }

        return labelString;
    }

    public static generateTripCode(
        serviceProviderId: number,
        serviceProvider: ServiceProvider,
        destinationArea: DestinationArea,
        ship?: Ship
    ): string {
        let code = serviceProvider.hanseatTripcodeShort;
        if (ship) {
            code += ship.tripCodeShort;
        } else {
            code += destinationArea.isoShort;
        }
        code += '-' + destinationArea.name;
        const serviceProviderShort = serviceProviderId === TripConstants.serviceProviderHrw ? '-E' : '-V';
        code += serviceProviderShort;

        return code;
    }

}
