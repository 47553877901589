import * as moment from 'moment';
import {finalize} from 'rxjs/operators';
import {User} from '../../models/user/user.model';
import {Trip} from '../../models/trip/trip.model';
import {Task} from 'ng-src/models/task/task.model';
import {TripHelper} from '../../helper/trip.helper';
import {UserHelper} from '../../helper/user.helper';
import {ActivatedRoute, Router} from '@angular/router';
import {Config} from '../../constants/config.constants';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Component, Inject, OnInit} from '@angular/core';
import {TaskService} from '../../service/task/task.service';
import {UserService} from '../../service/user/user.service';
import {toHtmlString} from '../../extensions/functions.helper';
import {ClaimsConstants} from '../../constants/claims.constants';
import {todayOrFuture} from '../global/validation/date.validator';
import {SnackbarConfig} from '../../constants/snackbar.constants';
import {FeedbackConstants} from '../../constants/feedback.constants';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TaskComment} from '../../models/notification/task-comment.model';
import {LoadingService} from '../../service/local/loading/loading.service';
import {AdvertisingSpot} from '../../models/advertising-spot/advertising-spot.model';
import {faCheck, faExternalLinkAlt, faTimes} from '@fortawesome/free-solid-svg-icons';
import {AdvertisingMaterialConstants} from '../../constants/advertising-material.constants';
import {AdvertisingMaterial} from '../../models/advertising-material/advertising-material.model';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

    // Status
    statusInReview = 1;
    statusApproved = 2;
    statusFeedbackStarted = 3;
    statusInReviewWithoutFeedback = 4;

    // Icons
    faCheck = faCheck;
    faTimes = faTimes;
    faExternalLinkAlt = faExternalLinkAlt;
    readonly timeFormat = 'HH:mm';
    readonly routerState = '_routerState';

    // Form
    feedbackForm: UntypedFormGroup;
    subjectFC = new UntypedFormControl('', [Validators.required]);
    descriptionFC = new UntypedFormControl('', [Validators.required]);
    startDateFC = new UntypedFormControl('');
    deadlineFC = new UntypedFormControl('', [Validators.required, todayOrFuture()]);
    deadlineTimeFC = new UntypedFormControl('');
    createdByFC = new UntypedFormControl('', [Validators.required]);
    reviewerFC = new UntypedFormControl('', [Validators.required]);
    folderLinkArrayFC = new UntypedFormArray([]);
    versionFC = new UntypedFormControl(1, [Validators.pattern(Config.wholeNumberPattern)]);
    attachmentFC = new UntypedFormControl([]);
    deletedAttachments = new UntypedFormControl([]);

    // Values
    task: Task;
    id: number;
    originRoute: string;
    comments: TaskComment[] = [];
    currentUser: User;
    advMat: AdvertisingMaterial;
    advSpot: AdvertisingSpot;
    trips: Trip[];
    type: number;
    users: User[];
    submitting = false;

    // Constants
    advMatTaskTypes = AdvertisingMaterialConstants.advMatTaskTypes;
    strings = FeedbackConstants.strings;
    actions = FeedbackConstants.actions;
    status = FeedbackConstants.status;

    // Recht 'Review durchführen'
    claimReview = {
        claim: ClaimsConstants.sectionNames.review
    };

    lastFolderLinkPath: string;

    constructor(public dialogRef: MatDialogRef<FeedbackComponent>,
                @Inject(MAT_DIALOG_DATA) public data,
                private formBuilder: UntypedFormBuilder,
                private snackBar: MatSnackBar,
                private loadingService: LoadingService,
                private taskService: TaskService,
                private userService: UserService,
                private route: ActivatedRoute,
                private router: Router) {
        this.userService.currentUser.subscribe(u => this.currentUser = u);
        this.feedbackForm = formBuilder.group({
            subject: this.subjectFC,
            description: this.descriptionFC,
            startDate: this.startDateFC,
            deadline: this.deadlineFC,
            deadlineTime: this.deadlineTimeFC,
            createdBy: this.createdByFC,
            reviewer: this.reviewerFC,
            folderLinks: this.folderLinkArrayFC,
            version: this.versionFC,
            attachments: this.attachmentFC
        });
    }

    private static getUserAdvertisment(task): User | null {
        let reviewer = null;
        const advertisingSpot = task.advertisingSpot ? task.advertisingSpot : task.advSpot;
        const advertisingMaterial = task.advertisigMaterials ? task.advertisingMaterials[0] : task.advMat;
        if (advertisingSpot) {
            reviewer = advertisingSpot.responsibleUser;
        }
        if (advertisingMaterial && !reviewer && advertisingMaterial.advertisingSpot) {
            reviewer = advertisingMaterial.advertisingSpot.responsibleUser;
        }

        if (!reviewer) {
            reviewer = task.createdBy;
        }

        return reviewer;
    }

    private static buildDateTimeString(date: any, time: string): string {
        return `${toHtmlString(date)} ${time ? time : '00:00'}`;
    }

    ngOnInit() {
        this.id = Number(this.route.snapshot.paramMap.get('id'));
        this.originRoute = this.route.snapshot[this.routerState].url.split('?')[0];

        // set initial Values
        if (this.data.task) {
            // if process was already started
            this.loadingService.showSpinner();
            this.taskService.get(this.data.task.id)
                .pipe(finalize(() => this.loadingService.hideSpinner()))
                .subscribe(response => {
                        this.data.task = response;
                        this.comments = this.data.task.taskComments;
                        this.task = this.data.task;

                        // setzte Reviewer abhängig vom Status der Review-Aufgabe
                        let reviewer = this.task.responsibleUser;
                        if (this.task.status.id === this.statusFeedbackStarted) {
                            // Reviewer -> Verantwortlicher Werbung aus dem Werbeplatz oder dem Werbemittel
                            // Falls Beides nicht gesetzt, wird der Ersteller verwendet
                            reviewer = FeedbackComponent.getUserAdvertisment(this.task);
                        }
                        if (
                            this.task.status.id === this.statusInReview ||
                            this.data.task.status.id === this.statusInReviewWithoutFeedback ||
                            this.task.status.id === this.statusApproved
                        ) {
                            // Reviewer -> PM einer der Reisen
                            if (this.task.advertisingSpot) {
                                reviewer = this.getFirstResponsibleUserFromTrips(this.task.advertisingSpot.trips);
                            } else if (this.task.advertisingMaterials[0]?.advertisingSpot) {
                                reviewer = this.getFirstResponsibleUserFromTrips(
                                    this.task.advertisingMaterials[0].advertisingSpot.trips
                                );
                            } else {
                                reviewer = this.task.createdBy;
                            }
                        }

                        this.reviewerFC.setValue(reviewer);
                        this.reviewerFC.markAsDirty();
                        this.subjectFC.setValue(this.task.name);
                        this.descriptionFC.setValue(this.task.description);
                        this.startDateFC.setValue(this.task.startDate);
                        if (this.task.endDate) {
                            this.deadlineFC.setValue(new Date(this.task.endDate));
                            this.deadlineTimeFC.setValue(moment(this.task.endDate).parseZone().format(this.timeFormat));
                        }
                        this.createdByFC.setValue(this.task.createdBy);

                        if (this.task.folderLinks.length > 0) {
                            let currentVersion = this.task.version - this.task.folderLinks.length + 1;
                            if (this.displayFeedbackButton()) {
                                currentVersion = currentVersion - 1;
                            }
                            this.task.folderLinks.forEach((folderLink, key) => {
                                this.folderLinkArrayFC.push(
                                    this.createFolderLinkGroup(folderLink, currentVersion + key)
                                );
                                this.lastFolderLinkPath = folderLink.path;
                            });
                        }
                        if (this.displayFeedbackButton() || this.displayRestartFeedbackButton()) {
                            let newVersion = this.task.version;
                            if (this.displayRestartFeedbackButton()) {
                                newVersion = 1;
                            }
                            this.folderLinkArrayFC.push(
                                this.createFolderLinkGroup({path: this.lastFolderLinkPath}, newVersion)
                            );
                        }
                        this.versionFC.setValue(this.task.version);
                        if (this.data.task.attachments) {
                            this.data.task.attachments.forEach(
                                a => this.attachmentFC.value.push({file: a, new: false}));
                        }
                        this.id = this.data.task.id;

                        this.advMat = this.data.task.advertisingMaterials[0];
                        this.advSpot = this.data.task.advertisingSpot ?? this.advMat.advertisingSpot;
                        this.advSpot.trips = this.data.task.trips;
                    }
                );
        } else {
            // if new review process
            this.subjectFC.setValue(this.data.subject);
            this.descriptionFC.setValue(this.data.description);
            this.startDateFC.setValue(this.data.startDate);
            this.createdByFC.setValue(this.data.createdBy);
            if (this.data.advSpot?.trips) {
                this.reviewerFC.setValue(this.getFirstResponsibleUserFromTrips(this.data.advSpot.trips));
            }
            this.folderLinkArrayFC.push(this.createFolderLinkGroup({path: '', none: ''}, 1));

            this.advMat = this.data.advMat;
        }

        // fetch users from backend if not provided
        if (!this.data.users) {
            this.userService.getAll('enabled=1')
                .subscribe(users => this.users = users);
        } else {
            this.users = this.data.users;
        }

        this.type = this.data.advMatTaskTypeId;

        if (this.type === this.advMatTaskTypes.default) {
            if (!this.data.advSpot && this.data.task) {
                this.advSpot = this.data.task.advertisingSpot ?? this.data.advMat.advertisingSpot;
            } else {
                this.advSpot = this.data.advSpot;
            }

            if (this.advSpot?.trips.length <= 0) {
                this.advSpot.trips = this.data.task.trips;
            }

        }
    }

    openInNewTab() {
        if (this.advSpot) {
            const url = this.router.serializeUrl(
                this.router.createUrlTree([this.originRoute],
                    {queryParams: {openFeedbackForAdvSpotId: this.advSpot.id}})
            );
            window.open(url, '_blank');
        } else if (this.id) {
            const url = this.router.serializeUrl(
                this.router.createUrlTree([this.originRoute], {queryParams: {openFeedbackForTaskId: this.id}})
            );
            window.open(url, '_blank');
        }
    }

    getUserFullName = (user: User) => UserHelper.getFullName(user);

    getTripLabel = (trip: Trip) => TripHelper.getLabel(trip);

    getErrorMessage(field: AbstractControl) {
        field = field as UntypedFormControl;
        if (field.errors.past) {
            return 'Datum darf nicht in der Vergangenheit liegen';
        }
        return 'Feld darf nicht leer sein';
    }

    uploadAttachment(fileInput: any): void {
        for (const uploadedFile of fileInput.files) {
            this.attachmentFC.value.push({file: uploadedFile, new: true});
        }
        this.attachmentFC.markAsDirty();
    }

    sendTask() {
        if (this.hasNoNewFolderLink()) {
            this.snackBar.open(
                'Bitte geben Sie noch den Link zur neuen Version an!',
                '',
                SnackbarConfig.error
            );
            return;
        }
        if (this.feedbackForm.valid) {
            if (!this.task) {
                this.sendNewTask();
            } else {
                this.performAction(this.actions.askForFeedback);
            }
        }
    }

    performAction(action: string) {
        if (action === this.actions.restartReview && this.hasNoNewFolderLink()) {
            this.snackBar.open(
                'Wenn Sie das Review neu starten wollen, müssen Sie einen neuen Link angeben!',
                '',
                SnackbarConfig.error
            );
            return;
        }
        if (action === this.actions.approve && this.hasNoNewFolderLink()) {
            this.snackBar.open(
                'Für die freizugebende Version steht kein Link bereit!',
                '',
                SnackbarConfig.error
            );
            return;
        }
        // gets called on button press after initial step in the feedback process
        if (this.feedbackForm.valid) {
            const task = this.generateBody();
            this.tryToDeleteAttachments(task, action);
        }
    }

    // functions to determine if a button should be shown
    displayFeedbackButton(): boolean {
        return !this.task ||
            (
                (
                    this.task.status?.id === this.status.inReview ||
                    this.task?.status?.id === this.status.inReviewWithNoFurtherFeedback
                ) &&
                (
                    this.task?.responsibleUser?.id === this.currentUser.id
                    || this.userService.hasClaim(ClaimsConstants.adminClaim)
                    || this.userService.hasClaim(this.claimReview)
                )
            );
    }

    displayRestartFeedbackButton(): boolean {
        return !this.displayFeedbackAndReviewButton() && !this.displayApproveButton() && !this.displayFeedbackButton();
    }

    displayFinishButton(): boolean {
        return this.displayRestartFeedbackButton() && this.task?.status?.id !== this.status.done;
    }

    displayApproveButton(): boolean {
        return (this.task?.status?.id === this.status.feedback
                || this.task?.status?.id === this.status.inReview
                || this.task?.status?.id === this.status.inReviewWithNoFurtherFeedback)
            && (this.task?.responsibleUser?.id === this.currentUser.id
                || this.userService.hasClaim(ClaimsConstants.adminClaim)
                || this.userService.hasClaim(this.claimReview));
    }

    displayFeedbackAndReviewButton(): boolean {
        return (this.task?.status?.id === this.status.feedback)
            && (this.task?.responsibleUser?.id === this.currentUser.id
                || this.userService.hasClaim(ClaimsConstants.adminClaim)
                || this.userService.hasClaim(this.claimReview));
    }

    displayAppliedFeedbackAndApproveButton(): boolean {
        return (this.task?.status?.id === this.status.feedback)
            && (this.task?.responsibleUser?.id === this.currentUser.id
                || this.userService.hasClaim(ClaimsConstants.adminClaim)
                || this.userService.hasClaim(this.claimReview));
    }

    // posts a comment on an existing task
    commentsChanged(comments: TaskComment[]) {
        if (this.task) {
            this.submitting = true;
            // because the comments are sorted newest first,
            // the first element will be the one that needs to be sent to the server
            const newComment = comments[0];
            this.taskService.postComment(this.task.id, newComment)
                .subscribe({
                    next: () => {
                        this.snackBar.open('Kommentar wurde angelegt', '',
                            SnackbarConfig.success);
                    },
                    error: () => {
                        this.snackBar.open('Kommentar konnte nicht angelegt werden', '',
                            SnackbarConfig.error);
                    },
                    complete: () => {
                        this.submitting = false;
                    }
                });
        }
        this.comments = comments;
    }

    saveReview() {
        if (this.feedbackForm.valid) {
            const task = this.generateBody();
            this.tryToDeleteAttachments(task);
        }
    }

    createFolderLinkGroup(folderLink, version): UntypedFormGroup {
        return new UntypedFormGroup({
            path: new UntypedFormControl(folderLink.path),
            version: new UntypedFormControl(version),
        });
    }

    hasNoNewFolderLink() {
        const folderLinks = this.feedbackForm.controls.folderLinks as UntypedFormArray;
        return !(folderLinks.at(folderLinks.length - 1).value.path);
    }

    private sendNewTask() {
        this.submitting = true;
        const task = this.generateBody();

        // transform into FormData due to attachments
        const formData = this.taskService.toFormData(task);

        this.loadingService.showSpinner();
        this.taskService.createAdvertisingMaterialTask(formData)
            .pipe(finalize(() => this.loadingService.hideSpinner()))
            .subscribe({
                next: () => {
                    this.snackBar.open('Anfrage wurde gestellt', '', SnackbarConfig.success);
                    this.dialogRef.close(true);
                },
                error: () => {
                    this.snackBar.open('Anfrage konnte nicht erstellt werden', '', SnackbarConfig.error);
                },
                complete: () => {
                    this.submitting = false;
                }
            });
    }

    private generateBody() {
        // generate new AdvertisingTask
        const task: any = {};

        if (this.attachmentFC.value.length > 0) {
            const newFiles = [];
            const oldFiles = [];
            for (const file of this.attachmentFC.value) {
                if (file.new) {
                    newFiles.push(file.file);
                } else {
                    oldFiles.push(file.file);
                }
            }

            if (newFiles.length > 0) {
                task.newFiles = newFiles;
            }

            if (oldFiles.length > 0) {
                task.attachments = oldFiles;
            }
        }

        if (this.deadlineTimeFC.dirty || this.deadlineFC.dirty) {
            task.endDate = FeedbackComponent.buildDateTimeString(this.deadlineFC.value, this.deadlineTimeFC.value);
        }

        if (this.folderLinkArrayFC.dirty) {
            task.folderLinks = this.folderLinkArrayFC.value;
        }

        // these fields only need to be sent at creation
        if (!this.task) {
            delete task.deletedAttachments;

            // readonly fields
            task.advertisingTaskType = this.data.advMatTaskTypeId;
            task.name = this.subjectFC.value;
            task.description = this.descriptionFC.value;
            task.startDate = toHtmlString(new Date(this.startDateFC.value), true);

            task.responsibleUser = this.reviewerFC.value.id;

            // comments only need to be sent here because
            // if there already is a task they will be sent the moment they are created
            if (this.comments.length > 0) {
                task.taskComments = this.comments;
            }

            if (task.advertisingTaskType === this.advMatTaskTypes.default && this.data.advMat.insertion) {
                task.advertisingSpot = this.advSpot.id;
            }

            // TODO might be changed to only accept a single value
            task.advertisingMaterials = [this.data.advMat.id];
        } else {
            // this only has to happen if there already is a task
            if (this.reviewerFC.dirty) {
                task.responsibleUser = this.reviewerFC.value.id;
            }
        }

        return task;
    }

    private tryToDeleteAttachments(task: any, action?: string): void {
        if (task.attachments) {
            this.submitting = true;
            // Reihenfolge wichtig
            task.attachments = task.attachments.sort((a1, a2) => a1.id > a2.id);
            task.attachments = task.attachments.map(a => ({delete: a.delete}));

            this.taskService.deleteAttachments(this.id, task).subscribe({
                    next: () => {
                        delete task.attachments;
                        this.tryToUploadNewFiles(task, action);
                    },
                    error: () => {
                        this.snackBar.open(
                            'Die Aufgabe konnte nicht geändert werden!',
                            '',
                            SnackbarConfig.error);
                    },
                    complete: () => {
                        this.submitting = false;
                    }
                }
            );
        } else {
            this.tryToUploadNewFiles(task, action);
        }
    }

    private tryToUploadNewFiles(task: any, action?: string): void {
        if (task.newFiles) {
            this.submitting = true;
            let index = 0;
            task.newFiles.forEach((file: File) => {
                this.taskService.uploadAttachment(this.id, file)
                    .subscribe({
                        next: () => {
                            if (task.newFiles.length - 1 === index) {
                                delete task.newFiles;
                                this.tryToPatchTask(task, action);
                            } else {
                                index = index + 1;
                            }
                        },
                        error: () => {
                            this.snackBar.open(
                                'Die Aufgabe konnte nicht geändert werden!',
                                '',
                                SnackbarConfig.error);
                        },
                        complete: () => {
                            this.submitting = false;
                        }
                    });
            });
        } else {
            this.tryToPatchTask(task, action);
        }
    }

    private tryToPatchTask(task: any, action?: string): void {

        if (action === 'approve' || action === 'giveFeedbackAndApprove' || action === 'finishReview') {
            task.responsibleUser = this.task.createdBy.id;
        }

        if (task.length !== 0) {
            this.submitting = true;
            if (task.folderLinks) {
                const folderLinks = [];
                task.folderLinks.forEach((folderLink) => {
                    if (folderLink.path) {
                        folderLinks.push({path: folderLink.path});
                    }
                });
                task.folderLinks = folderLinks;
            }
            this.loadingService.showSpinner();
            this.taskService.performAction(this.task.id, task, action)
                .pipe(finalize(() => this.loadingService.hideSpinner()))
                .subscribe({
                    next: (response: Task) => {
                        this.dialogRef.close(response);
                    },
                    error: () => {

                    },
                    complete: () => {
                        this.submitting = false;
                    }
                });
        } else {
            this.snackBar.open(
                'Änderungen wurden erfolgreich übernommen',
                '',
                SnackbarConfig.success);
        }
    }

    private getFirstResponsibleUserFromTrips(trips): User | null {
        let responsibleUser = null;
        trips.forEach((trip: Trip) => {
            if (trip.responsibleUser) {
                responsibleUser = trip.responsibleUser;
            }
        });
        return responsibleUser;
    }
}
