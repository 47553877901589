import {Injectable} from '@angular/core';
import {EMPTY, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {User} from '../models/user/user.model';
import {UserService} from '../service/user/user.service';
import {AppRoutes} from '../constants/navigation.constants';
import {LoadingService} from '../service/local/loading/loading.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserGuard  {

    constructor(private userService: UserService,
                private loadingService: LoadingService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {

        if (this.userService.isLoggedIn()) {
            return true;
        }

        return this.router.parseUrl(AppRoutes.app.login.fullPath);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.userService.getCurrentUser()) {
            return true;
        }

        return this.userService.updateMyProfile()
            .pipe(
                map<User, boolean>(user => {
                    if (user) {
                        return true;
                    } else {
                        this.router.navigate([AppRoutes.app.login.fullPath]).then();
                        return false;
                    }
                })
            );
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User>
        | Promise<User>
        | User {

        return this.userService.updateMyProfile()
            .pipe(
                mergeMap(user => {
                    if (user) {
                        return of(user);
                    } else {
                        this.router.navigate([AppRoutes.app.login.fullPath]).then();
                        return EMPTY;
                    }
                })
            );
    }
}
