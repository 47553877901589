import {MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

export class SnackbarConfig {

    public static success = {
        panelClass: 'snackbar_success',
        duration: 5000
    };

    public static warning = {
        panelClass: 'snackbar_warning',
        duration: 5000
    };

    public static error = {
        panelClass: 'snackbar_error',
        duration: 5000
    };

    public static loading = {
        panelClass: 'snackbar_loading',
        verticalPosition: 'top' as MatSnackBarVerticalPosition
    };
}
