<div class="background">
    <mat-card appearance="outlined" class="card">
        <form [formGroup]="loginForm"
              (ngSubmit)="onSubmit(loginForm.value)">
            <div class="container">

                <object data="app/assets/lista-logo-login.svg" type="image/svg+xml">
                </object>

                <div class="form_container">
                    <mat-form-field>
                        <input required
                               matInput
                               placeholder="E-Mail"
                               [formControl]="email"
                               autocomplete="username"
                        >
                        <mat-error *ngIf="email.invalid">{{getErrorMessageForEmail()}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <input required
                               matInput
                               type="password"
                               placeholder="Passwort"
                               (change)="invalidPassword = false"
                               [formControl]="password"
                               autocomplete="current-password"
                        >
                        <mat-error *ngIf="password.invalid">{{getErrorMessageForPassword()}}</mat-error>
                    </mat-form-field>
                    <a routerLink="/app/forgot-password">Passwort vergessen</a>
                </div>
                <button
                    class="login_button"
                    color="primary"
                    mat-raised-button
                    type="submit"
                    [disabled]="loginForm.touched && !loginForm.valid">
                        Login
                </button>
            </div>
        </form>
    </mat-card>
</div>
