import {User} from '../../../models/user/user.model';
import {UserService} from '../../../service/user/user.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TaskComment} from '../../../models/notification/task-comment.model';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit, OnChanges {
    @Input() comments: TaskComment[] = [];
    @Output() commentsChange = new EventEmitter<TaskComment[]>();
    newComment: UntypedFormGroup;
    commentText = new UntypedFormControl('');

    myUser: User;

    constructor(private formBuilder: UntypedFormBuilder,
                private userService: UserService) {
    }

    private static compare(a: TaskComment, b: TaskComment) {
        // convert date to Date-Object for comparison, if it is a string
        if (typeof a.createdAt === 'string') {
            a.createdAt = new Date(a.createdAt);
        }
        if (typeof b.createdAt === 'string') {
            b.createdAt = new Date(b.createdAt);
        }

        if (a.createdAt < b.createdAt) {
            return 1;
        }
        if (a.createdAt === b.createdAt) {
            return 0;
        }
        return -1;
    }

    ngOnInit() {
        this.newComment = this.formBuilder.group(this.commentText);
        this.comments?.sort(CommentComponent.compare);
        this.userService.currentUser.subscribe(u => this.myUser = u);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.comments) {
            this.comments?.sort(CommentComponent.compare);
        }
    }

    submit() {
        const newComment = new TaskComment();
        newComment.text = this.commentText.value;

        newComment.createdBy = this.myUser;
        newComment.createdAt = new Date();
        this.comments.unshift(newComment);
        this.comments.sort(CommentComponent.compare);
        this.commentsChange.emit(this.comments);

        this.commentText.reset();
    }
}
