import { Injectable } from '@angular/core';

/**
 * @todo refactoring - no need for static functions to be in an injectable class. Use regular class or just exported
 *      functions instead.
 */
@Injectable()
export class AutocompleteSelectUtil {
    buildDisplayStringFromFirstAndLastName = (option: any) => option.firstName + ' ' + option.lastName;

    buildDisplayStringFromShortCodeAndName = (option: any) => option.shortCode + ' - ' + option.name;

    buildDisplayStringFromLabel = (option: any) => option.label;

    buildDisplayStringFromLtCodeAndName = (option: any) => option?.ltCode ? option.ltCode + ' - ' + option.name : option.name;
}
