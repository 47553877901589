import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatTreeModule} from '@angular/material/tree';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MatBadgeModule} from '@angular/material/badge';
import {MatChipsModule} from '@angular/material/chips';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {CommonModule, DatePipe} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {AuthDirective} from './directives/auth.directive';
import {LinkDirective} from './directives/link.directive';
import {MatDividerModule} from '@angular/material/divider';
import {CommentComponent} from './comment/comment.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {SearchbarComponent} from './searchbar/searchbar.component';
import {IsInTeamDirective} from './directives/is-in-team.directive';
import {DateAdapter} from '@angular/material/core';
import {MatOptionModule} from '@angular/material/core';
import {TopbarComponent} from './navigation/topbar/topbar.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {UserComponent} from './navigation/topbar/user/user.component';
import {LoadingComponent} from './snackbar/loading/loading.component';
import {NavigationComponent} from './navigation/navigation.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {FeedbackService} from '../../service/feedback/feedback.service';
import {TopMenuComponent} from './navigation/top-menu/top-menu.component';
import {ContainerComponent} from './layout/container/container.component';
import {LoadingService} from '../../service/local/loading/loading.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DelayedInputComponent} from './delayed-input/delayed-input.component';
import {ShowDayOfWeekDirective} from './directives/show-day-of-week.directive';
import {CommentItemComponent} from './comment/comment-item/comment-item.component';
import {UnauthorizedInterceptor} from '../../interceptors/unauthorized.interceptor';
import {DeleteDialogComponent} from './dialogs/delete-dialog/delete-dialog.component';
import {AutocompleteSelectUtil} from './autocomplete-select/autocomplete-select-util';
import {ConfirmDialogComponent} from './dialogs/confirm-dialog/confirm-dialog.component';
import {MatSelectSearchComponent} from './mat-select-search/mat-select-search.component';
import {ShowDayOfWeekShortDirective} from './directives/show-day-of-week-short.directive';
import {TasksToComponentComponent} from './tasks-to-component/tasks-to-component.component';

import {ResizableDirective} from './excel-like-filter/directives/resizable/resizable.directive';
import {ListControlsComponent} from './excel-like-filter/list-controls/list-controls.component';
import {AutocompleteSelectComponent} from './autocomplete-select/autocomplete-select.component';
import {LabeledContainerComponent} from './layout/labeled-container/labeled-container.component';
import {ListViewButtonGroupComponent} from './list-view-button-group/list-view-button-group.component';
import {DateFilterMenuComponent} from './excel-like-filter/date-filter-menu/date-filter-menu.component';
import {FilterTableDirective} from './excel-like-filter/directives/filter-table/filter-table.directive';
import {BeforeUnloadDialogComponent} from './dialogs/before-unload-dialog/before-unload-dialog.component';
import {NotificationBellComponent} from './navigation/topbar/notification-bell/notification-bell.component';
import {DateFilterButtonGroupComponent} from './date-filter-button-group/date-filter-button-group.component';
import {FilterMenuComponent} from './excel-like-filter/filter-table-header/filter-menu/filter-menu.component';
import {FilterChipComponent} from './excel-like-filter/filter-display-header/filter-chip/filter-chip.component';
import {FilterTableHeaderComponent} from './excel-like-filter/filter-table-header/filter-table-header.component';
import {FilterButtonGroupComponent} from './excel-like-filter/filter-button-group/filter-button-group.component';
import {FilterDisplayHeaderComponent} from './excel-like-filter/filter-display-header/filter-display-header.component';
import {InfiniteScrollContainerComponent} from './layout/infinite-scroll-container/infinite-scroll-container.component';
import {
    SelectionTreeComponent
} from './excel-like-filter/filter-table-header/filter-menu/selection-tree/selection-tree.component';
import {
    NotificationItemComponent
} from './navigation/topbar/notification-bell/notification-item/notification-item.component';
import {MultiselectComponent} from './multiselect/multiselect.component';
import {
    DialogSecurityQueryComponent,
    TableSettingsComponent
} from './excel-like-filter/table-settings/table-settings.component';
import { TableHeaderMenuComponent } from './excel-like-filter/table-header-with-menu/table-header-menu/table-header-menu.component';
import { TableHeaderTreeFilterComponent
} from './excel-like-filter/table-header-with-menu/table-header-menu/table-header-tree-filter/table-header-tree-filter.component';
import { TableHeaderWithMenuComponent } from './excel-like-filter/table-header-with-menu/table-header-with-menu.component';
import { TableHeaderIntegerFilterComponent
} from './excel-like-filter/table-header-with-menu/table-header-menu/table-header-integer-filter/table-header-integer-filter.component';
import {MatSliderModule} from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
    declarations: [
        NavigationComponent,
        TopbarComponent,
        SearchbarComponent,
        UserComponent,
        TopMenuComponent,
        FilterMenuComponent,
        SelectionTreeComponent,
        FilterChipComponent,
        FilterDisplayHeaderComponent,
        DeleteDialogComponent,
        ConfirmDialogComponent,
        ContainerComponent,
        DateFilterMenuComponent,
        InfiniteScrollContainerComponent,
        LoadingComponent,
        FilterTableHeaderComponent,
        FilterTableDirective,
        ListControlsComponent,
        TableSettingsComponent,
        DelayedInputComponent,
        IsInTeamDirective,
        AuthDirective,
        DateFilterButtonGroupComponent,
        AutocompleteSelectComponent,
        MultiselectComponent,
        ShowDayOfWeekDirective,
        ShowDayOfWeekShortDirective,
        LinkDirective,
        TasksToComponentComponent,
        FilterButtonGroupComponent,
        BeforeUnloadDialogComponent,
        LabeledContainerComponent,
        CommentComponent,
        CommentItemComponent,
        NotificationBellComponent,
        NotificationItemComponent,
        MatSelectSearchComponent,
        ListViewButtonGroupComponent,
        ResizableDirective,
        DialogSecurityQueryComponent,
        TableHeaderMenuComponent,
        TableHeaderTreeFilterComponent,
        TableHeaderWithMenuComponent,
        TableHeaderIntegerFilterComponent
    ],
    imports: [
        CommonModule,
        MatGridListModule,
        MatDividerModule,
        MatFormFieldModule,
        FontAwesomeModule,
        MatInputModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatButtonModule,
        RouterModule,
        MatTreeModule,
        MatCheckboxModule,
        MatIconModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatTableModule,
        MatButtonToggleModule,
        MatOptionModule,
        MatSelectModule,
        MatTabsModule,
        FormsModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatDatepickerModule,
        MatSliderModule,
        MatSlideToggleModule
    ],
    exports: [
        NavigationComponent,
        SearchbarComponent,
        FilterMenuComponent,
        FilterChipComponent,
        FilterDisplayHeaderComponent,
        ContainerComponent,
        DateFilterMenuComponent,
        InfiniteScrollContainerComponent,
        FilterTableHeaderComponent,
        FilterTableDirective,
        TableSettingsComponent,
        AuthDirective,
        IsInTeamDirective,
        DateFilterButtonGroupComponent,
        ListViewButtonGroupComponent,
        AutocompleteSelectComponent,
        MultiselectComponent,
        ShowDayOfWeekDirective,
        ShowDayOfWeekShortDirective,
        LinkDirective,
        TasksToComponentComponent,
        FilterButtonGroupComponent,
        LabeledContainerComponent,
        CommentComponent,
        MatSelectSearchComponent,
        DialogSecurityQueryComponent,
        TableHeaderTreeFilterComponent,
        TableHeaderWithMenuComponent
    ],
    bootstrap: [
        DeleteDialogComponent,
        ConfirmDialogComponent,
        LoadingComponent
    ],
    providers: [
        DatePipe,
        LoadingService,
        FeedbackService,
        AutocompleteSelectUtil,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedInterceptor,
            multi: true
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter
        },
    ]
})
export class GlobalModule {
}
