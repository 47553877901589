import {ListView} from '../models/list-view/list-view.model';
import {DateFilterButtonGroupValue} from '../app/global/date-filter-button-group/date-filter-button-group-value.model';

export class AdvertisingMaterialConstants {
    public static readonly tableHeaderValues = [
        'expand',
        'enabled',
        'KW',
        'status',
        'publicationDate',
        'marketingBriefingDeadline',
        'announcedFor',
        'announcedForUser',
        'medium',
        'type',
        'mediumFormat',
        'adcode',
        'deadline',
        'online',
        'responsibleUserAdvertisement',
        'actions'
    ];

    public static readonly defaultDisplayedValues = [
        'expand',
        'KW',
        'status',
        'publicationDate',
        'marketingBriefingDeadline',
        'announcedFor',
        'medium',
        'type',
        'mediumFormat',
        'adcode',
        'deadline',
        'online',
        'responsibleUserAdvertisement',
        'actions'
    ];

    public static readonly columnTitleMap = {
        status: 'Status',
        medium: 'Medium',
        type: 'Art',
        mediumFormat: 'Format',
        adcode: 'Werbe-Code',
        announcedFor: 'Avisiert',
        announcedForUser: 'Avisiert durch Benutzer',
        marketingBriefingDeadline: 'Frist Marketing-Briefing',
        deadline: 'Freigabe bis',
        publicationDate: 'ET',
        responsibleUserAdvertisement: 'Verantwortung Werbung',
        online: 'online',
        actions: '',
        enabled: 'Aktiv/Inaktiv',
        kw: 'KW',

        // subtables
        tripType: 'Art',
        ship: 'Schiff',
        destinationAreas: 'Zielgebiet',
        startDate: 'Start',
        endDate: 'Ende',
        responsibleUser: 'PM',
        leafletStatus: 'Status Prospekt',
    };

    public static readonly columnFilterMap = {
        enabled: 'enabled',
        kw: 'publicationDateWeek',
        status: 'status|name',
        medium: 'sharedValueMediumShortCode',
        type: 'type|name',
        mediumFormat: 'sharedValueMediumFormatName',
        adcode: 'sharedValueAdcode',
        announcedFor: 'announcedFor',
        announcedForUser: 'announcedForUserFullName',
        marketingBriefingDeadline: 'sharedValueMarketingBriefingDeadline',
        deadline: 'sharedValueAdvertisingMaterialDeadline',
        publicationDate: 'sharedPublicationDate',
        responsibleUser: 'sharedValueResponsibleUserTrip',
        responsibleUserAdvertisement: 'responsibleUserAdvertisementFullName',
        online: 'sharedValueOnline'
    };

    public static readonly defaultFilter = {
        enabled: ['Aktiv']
    };

    public static readonly defaultListView: ListView = new ListView().deserialize(
        {
            id: null,
            isPersonal: null,
            createdBy: null,
            teams: null,
            activeUsers: null,
            list: 'Werbemittel',
            name: 'Standard',
            undeletable: false,
            value: JSON.stringify({
                columns: AdvertisingMaterialConstants.defaultDisplayedValues,
                filter: AdvertisingMaterialConstants.defaultFilter
            })
        }
    );

    public static readonly advMatTaskTypes = {
        default: 1,
        overall: 2,
        editor: 3
    };

    public static readonly dateFilterButtonGroupValues: DateFilterButtonGroupValue[] = [
        {
            content: 'geplant',
            value: 'planned'
        },
        {
            content: 'Heute',
            value: 'today'
        },
        {
            content: 'Diese Woche',
            value: 'this week'
        },
        {
            content: 'Nächste Woche',
            value: 'next week'
        },
        {
            content: 'Nächste 10 Tage',
            value: '10 days'
        }
    ];
}
