import {Injectable} from '@angular/core';
import {FeedbackComponent} from '../../app/feedback/feedback.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Task} from 'ng-src/models/task/task.model';
import {AdvertisingMaterial} from '../../models/advertising-material/advertising-material.model';
import {User} from '../../models/user/user.model';
import {AdvertisingSpot} from '../../models/advertising-spot/advertising-spot.model';

@Injectable()
export class FeedbackService {

    constructor(private dialog: MatDialog) { }

    openDialogFromTask(task: Task): MatDialogRef<FeedbackComponent> {
        return this.openDialog(
            task.name,
            task.description,
            task,
            task.advertisingTaskType.id,
            task.startDate,
            task.responsibleUser,
            task.createdBy,
            task.advertisingMaterials[0]
        );
    }

    openDialog(
        subject: string,
        description: string,
        task: Task,
        advMatTaskTypeId: number,
        startDate: Date,
        responsibleUser: User,
        createdBy: User,
        advMat: AdvertisingMaterial,
        users?: User[],
        advSpot?: AdvertisingSpot): MatDialogRef<FeedbackComponent> {
        return this.dialog.open(FeedbackComponent, {
            data: {
                advMat,
                subject,
                description,
                task,
                advMatTaskTypeId,
                startDate,
                responsibleUser,
                createdBy,
                users,
                advSpot,
            }
        });
    }
}
