import {Entity} from '../entity.model';
import {Team} from '../team/team.model';
import {UserData} from './user-data.model';
import {ListView} from '../list-view/list-view.model';
import {IConvertable} from '../interfaces/convertable';

export class User extends Entity implements IConvertable<UserData> {
    firstName: string;
    lastName: string;
    eMailAddress: string;
    enabled: boolean;
    description: string;
    avatarImagePath: string;
    createdAt: string;
    trips: any[];
    teams: Team[];
    activeListViews: ListView[];

    get formattedName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    convert(): UserData {
        return new UserData().deserialize(
            {
                id: this.id,
                enabled: this.enabled,
                avatar: this.avatarImagePath,
                name: `${this.firstName} ${this.lastName}`,
                eMailAddress: this.eMailAddress,
                description: this.description,
                teams: this.teams ? this.teams.map(team => team.name) : []
            }
        );
    }
}
