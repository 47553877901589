<div class="menu_container">
    <div *ngFor="let menuItem of menuItems">

        <ng-container *ngIf="menuItem.subMenus else simpleMenu">
            <div *appAuth="menuItem.claims" [ngClass]="menuItem.active ? 'menu_button_active' : 'menu_button'">
                <button mat-button class="menu_button" [matMenuTriggerFor]="menu">
                    <div class="button_content">

                        <fa-icon [icon]="menuItem.icon"></fa-icon>

                        <div class="collapsible">
                            {{menuItem.title}}
                            <fa-icon [icon]="faAngleDown"></fa-icon>
                        </div>

                    </div>
                </button>
                <mat-menu
                    #menu
                    class="topbar_menu_panel"
                >
                    <ng-container *ngFor="let subMenuItem of menuItem.subMenus">
                        <ng-container *ngIf="subMenuItem.title !== 'Dokumentation' else documentation">
                            <a *appAuth="[subMenuItem.claim]" class="submenu_button" [routerLink]="[subMenuItem.link]" mat-menu-item>
                                <span>
                                    <fa-icon [icon]="subMenuItem.icon"></fa-icon>
                                    {{subMenuItem.title}}
                                </span>
                            </a>
                        </ng-container>
                        <ng-template #documentation>
                            <a class="submenu_button" target="_blank" [href]="subMenuItem.link" mat-menu-item>
                                <span>
                                    <fa-icon [icon]="subMenuItem.icon"></fa-icon>
                                    {{subMenuItem.title}}
                                </span>
                            </a>
                        </ng-template>
                    </ng-container>
                </mat-menu>
            </div>
        </ng-container>

        <ng-template #simpleMenu class="menu_item">
            <a [routerLink]="[menuItem.link]">
                <button *appAuth="menuItem.claims" mat-button [ngClass]="menuItem.active ? 'menu_button_active' : 'menu_button'">
                    <div class="button_content">

                        <fa-icon [icon]="menuItem.icon"></fa-icon>

                        <div class="collapsible">
                            {{menuItem.title}}
                        </div>
                    </div>
                </button>
            </a>
        </ng-template>
    </div>
</div>
