import {ListView} from '../models/list-view/list-view.model';
import {UserService} from '../service/user/user.service';
import {DateFilterButtonGroupValue} from '../app/global/date-filter-button-group/date-filter-button-group-value.model';
import {faClock} from '@fortawesome/free-solid-svg-icons';
import {Injector} from '@angular/core';

export class TaskConstants {

    public static injector: Injector;

    public static readonly tableHeaderValues = [
        'status',
        'name',
        'type',
        'automatic',
        'trip',
        'tripServiceProvider',
        'tripResponsibleUsers',
        'tripCode',
        'tripDate',
        'tripExistsInTm',
        'startDate',
        'advertisingMaterial',
        'insertion',
        'endDate',
        'responsibleUserOrTeam',
        'createdBy',
        'actions'
    ];

    public static readonly columnTitleMap = {
        status: 'Status',
        name: 'Aufgabe',
        type: 'Typ',
        automatic: 'Art',
        trip: 'Zuordnung zu Reisen',
        tripServiceProvider: 'Veranstalter der Reise',
        tripResponsibleUsers: 'PM der Reise',
        tripCode: 'Reise-Codes',
        tripDate: 'Reise-Datum',
        advertisingMaterial: 'Zuordnung zu Werbemitteln',
        insertion: 'Zuordnung zu Insertionen',
        tripExistsInTm: 'Reise im TM',
        startDate: 'Startdatum',
        endDate: 'Deadline',
        responsibleUserOrTeam: 'Zugewiesen an',
        createdBy: 'Erstellt von'
    };

    public static readonly columnFilterMap = {
        status: 'status|name',
        name: 'name',
        type: 'type|name',
        automatic: 'type|automatic',
        trip: 'trips|title',
        tripServiceProvider: 'trips|serviceProvider|name',
        tripResponsibleUsers: 'tripResponsibleUsers',
        tripCode: 'trips|code',
        tripDate: 'tripDate',
        advertisingMaterial: 'sharedValueAdvertisingMaterialAdcode',
        insertion: 'insertions|adcode',
        startDate: 'startDate',
        endDate: 'endDate',
        responsibleUserOrTeam: 'responsibleUserOrTeam',
        createdBy: 'createdByFullName'
    };

    public static readonly defaultFilter = {};

    public static readonly defaultListView: ListView = new ListView().deserialize(
        {
            id: null,
            isPersonal: null,
            createdBy: null,
            teams: null,
            activeUsers: null,
            list: 'Aufgaben-Liste',
            name: 'Standard',
            undeletable: false,
            value: JSON.stringify({
                columns: TaskConstants.tableHeaderValues,
                filter: TaskConstants.defaultFilter
            })
        }
    );
    public static readonly strings = {
        automatic: 'automatisch',
        manual: 'manuell',
        active: 'Aktiv',
        inactive: 'Inaktiv',
        createTripTaskName: 'Reise anlegen',
        tripExistsInTm: 'Reise existiert bereits im Travelmaster',
        advMaterialFeedback: {
            description: 'Bitte ein Feedback zu dem Werbemittel geben.'
        },
        feedbackEditor: {
            description: 'Bitte das Lektorat durchführen.'
        },
        feedbackTotal: {
            description: 'Bitte die Gesamtabnahme durchführen.'
        }
    };

    public static readonly dateFilterButtonGroupValues: DateFilterButtonGroupValue[] = [
        {
            content: 'geplant',
            value: 'planned'
        },
        {
            content: faClock,
            isIcon: true,
            value: 'due'
        },
        {
            content: 'Heute',
            value: 'today'
        },
        {
            content: 'Morgen',
            value: 'tomorrow'
        },
        {
            content: 'Diese Woche',
            value: 'this week'
        },
        {
            content: 'Nächste Woche',
            value: 'next week'
        }
    ];

    public static readonly discriminators = {
        baseTask: 'BaseTask',
        advertisingTask: 'AdvertisingTask'
    };

    static getDefaultFilter() {
        const userService = TaskConstants.injector.get(UserService);

        return {
            responsibleUser: [`${userService.getCurrentUser().firstName} ${userService.getCurrentUser().lastName}`]
        };
    }

}
