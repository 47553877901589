import {Component} from '@angular/core';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {AppRoutes} from '../../../../constants/navigation.constants';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
    faPlus = faPlus;
    routes = AppRoutes;
}
