<div class="background">
    <mat-card appearance="outlined" class="card">
        <form
            [formGroup]="forgotPasswordForm"
            (ngSubmit)="onSubmit()"
        >
            <div class="container">
                <h2>Passwort zurücksetzen</h2>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="E-Mail"
                        formControlName="email"
                        autocomplete="username"
                        (keyup)="onEmailChange()"
                        (change)="onEmailChange()"
                    >
                </mat-form-field>
                <button
                    class="submit_button"
                    mat-raised-button
                    type="submit"
                    color="primary"
                    [disabled]="forgotPasswordForm.disabled || (forgotPasswordForm.touched && forgotPasswordForm.invalid)"
                >
                    Zurücksetzen
                </button>
            </div>
        </form>
    </mat-card>
</div>
