import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {User} from '../../../../../models/user/user.model';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {UserService} from '../../../../../service/user/user.service';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    faAngleDown = faAngleDown;

    user: User;

    constructor(private userService: UserService,
                private router: Router) {
    }

    ngOnInit() {
        this.userService
            .updateMyProfile()
            .subscribe();
        this.userService.currentUser.subscribe(user => {
            this.user = user;
        });
    }

    getImage() {
        return this.userService.getImage(this.user.avatarImagePath);
    }

    logout() {
        this.userService.logout();
        this.router.navigate(['/app/login']).then();
    }
}
