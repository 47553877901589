import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CrudService} from '../crud.service';
import {HttpClient} from '@angular/common/http';
import {Task} from '../../models/task/task.model';
import {environment} from '../../environments/environment';
import {TaskFilter} from '../../models/task/task-filter.model';
import {toHtmlString} from '../../extensions/functions.helper';
import {IFilterableEntityService} from '../filterable-entity.service';
import {TaskComment} from '../../models/notification/task-comment.model';

@Injectable({
    providedIn: 'root'
})
export class TaskService extends CrudService<Task> implements IFilterableEntityService<TaskFilter> {
    filterRoute = `${environment.backendUrl}/filters/task`;

    constructor(http: HttpClient) {
        super(http, `${environment.backendUrl}/tasks`);
    }

    public createTaskFromForm(taskForm: FormData): Observable<Task> {
        return this.http.post<Task>(this.route, taskForm).pipe(
            map(obj => this.newInstance(obj))
        );
    }

    public createAdvertisingMaterialTask(task: FormData): Observable<Task> {
        const route = `${this.route}/adv-material`;
        return this.http.post<Task>(route, task).pipe(
            map(obj => this.newInstance(obj))
        );
    }

    public getFilters(fieldName?: string): Observable<TaskFilter> {
        let route = this.filterRoute;
        if (fieldName) {
            route += `?fieldName=${fieldName}`;
        }
        return this.http.get<any>(route).pipe(
            map(obj => {
                if(obj.tripExistsInTm) {
                    obj.tripExistsInTm = obj.tripExistsInTm?.filter(v => !!v).map(b =>  b === '1' ? 'Ja' : 'Nein');
                }
                if (obj.automatic) {
                    obj.automatic = obj.automatic?.map(b => b ? 'automatisch' : 'manuell');
                }
                if (obj.trip) {
                    obj.trip = obj.trip?.map(value => value !== '' ? value : TaskFilter.notAssigned);
                }
                if (obj.tripCode) {
                    obj.tripCode = obj.tripCode?.map(value => value !== '' ? value : TaskFilter.notAssigned);
                }
                if (obj.responsibleUserOrTeam) {
                    obj.responsibleUserOrTeam = obj.responsibleUserOrTeam?.map(v => v !== '' ? v : TaskFilter.notAssigned);
                }
                if (obj.insertion) {
                    obj.insertion = obj.insertion?.map(value => value !== '' ? value : TaskFilter.notAssigned);
                }
                if (obj.advertisingMaterial) {
                    obj.advertisingMaterial = obj.advertisingMaterial?.map(value => value !== '' ? value : TaskFilter.notAssigned);
                }
                return new TaskFilter().deserialize(obj);
            })
        );
    }

    getAllTasksForComponent(id: number, component: string): Observable<Task[]> {
        const filterBody = {
            'status|name': 'offen,in Bearbeitung,Werbemittel in Review,' +
                'Werbemittel Feedback eingeholt,Werbemittel in Review ohne weiteres Feedback'
        };
        filterBody[`${component}|id`] = `${id}`;
        return this.filterAll('', filterBody);
    }

    getAllFinishedTasksForTrip(tripId: number): Observable<Task[]> {
        const filterBody = {
            'status|name': 'erledigt',
            'trips|id': `${tripId}`
        };
        return this.filterAll('orderBy=+editDate', filterBody);
    }

    public uploadAttachment(id: number, attachment: File): Observable<any> {
        const route = `${this.route}/${id}/attachments`;
        const uploadData = new FormData();
        uploadData.append('newFiles', attachment, attachment.name);
        return this.http.post(route, uploadData);
    }

    deleteAttachments(id: number, task: any): Observable<void> {
        const deleteBody = {
            body: {
                endDate: task.endDate,
                attachments: task.attachments,
            }
        };
        const route = `${this.route}/${id}/attachments`;
        return this.http.delete<void>(route, deleteBody);
    }

    toFormData(task): FormData {
        const formData = new FormData();
        for (const key in task) {
            if (task.hasOwnProperty(key)) {
                if (key === 'newFiles') {
                    task[key].forEach((file) => {
                        formData.append(key + '[]', file, file.name);
                    });
                } else if (key === 'trips' ||
                    key === 'insertions' ||
                    key === 'advertisingMaterials') {
                    task[key].forEach((value) => {
                        formData.append(key + '[]', value);
                    });
                } else if (key === 'folderLinks') {
                    task[key].forEach(folderLink => {
                        formData.append(key + '[][path]', folderLink.path);
                    });
                } else if (key === 'taskComments') {
                    task[key].forEach(comment => {
                        formData.append(key + '[][text]', comment.text);
                    });
                } else if (key === 'startDate' || key === 'endDate') {
                    formData.append(key, toHtmlString(task[key], true));
                } else {
                    formData.append(key, task[key]);
                }
            }
        }
        return formData;
    }

    performAction(id: number, task: any, action?: string): Observable<Task> {
        if (action) {
            task.action = action;
        }
        return this.patch(task, id);
    }

    postComment(id: number, comment: TaskComment): Observable<TaskComment> {
        const route = `${this.route}/${id}/comment`;
        const body = {
            text: comment.text
        };

        return this.http.post<TaskComment>(route, body).pipe(
            map(obj => new TaskComment().deserialize(obj))
        );
    }

    protected newInstance(obj: any): Task {
        return new Task().deserialize(obj);
    }
}
