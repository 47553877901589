import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../../service/user/user.service';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {SnackbarConfig} from '../../../constants/snackbar.constants';
import {mustMatch} from '../../global/validation/mustmatch.validator';

@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
    minNumberCharacters = 8;
    minStrength = 100;
    setPasswordForm;
    password = new UntypedFormControl('', [Validators.required]);
    confirmPassword = new UntypedFormControl('', [Validators.required]);
    private token: string;

    constructor(private formBuilder: UntypedFormBuilder,
                private snackBar: MatSnackBar,
                private route: ActivatedRoute,
                private router: Router,
                private userService: UserService) {
    }

    ngOnInit() {
        this.route.paramMap
            .subscribe(params => this.token = params.get('token'));

        this.setPasswordForm = this.formBuilder.group({
                password: this.password,
                confirmPassword: this.confirmPassword
            },
            {
                validators: [mustMatch(this.password, this.confirmPassword)]
            });

    }

    onSubmit(setPasswordValue) {
        if (this.setPasswordForm.valid) {
            this.userService.setPassword(setPasswordValue.password, setPasswordValue.confirmPassword, this.token)
                .subscribe(
                    () => {
                        this.snackBar.open(
                            'Ihr Passwort wurde erfolgreich geändert',
                            '',
                            SnackbarConfig.success);
                        this.router.navigateByUrl('/app/login').then();
                    }
                );
        }
    }

    getConfirmPasswordError(): string {
        if (this.confirmPassword.errors.mustMatch) {
            return 'Passwörter stimmen nicht überein';
        }
    }

    onStrengthChanged(strength: number) {
        if (strength < this.minStrength) {
            this.password.setErrors({unsafe: true, error: 'unsafe'});
        } else {
            this.password.setErrors(null);
        }
    }

    getErrorMessage(formControl: UntypedFormControl): string {
        if (formControl.hasError('unsafe')) {
            return 'Passwortsicherheit ungenügend';
        }
        if (formControl.hasError('required')) {
            return 'Feld darf nicht leer sein';
        }
    }

}
