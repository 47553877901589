import {Component, Input} from '@angular/core';
import {UserService} from '../../../../../../service/user/user.service';
import {FeedbackService} from '../../../../../../service/feedback/feedback.service';
import {Notification} from '../../../../../../models/notification/notification.model';
import {NotificationService} from '../../../../../../service/notification/notification.service';

@Component({
    selector: 'app-notification-item',
    templateUrl: './notification-item.component.html',
    styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent {

    @Input() notification: Notification;

    constructor(private userService: UserService,
                private feedbackService: FeedbackService,
                private notificationService: NotificationService) {
    }

    getAvatar(): string {
        return this.userService.getImage(this.notification.triggeringUser.avatarImagePath);
    }

    openDialog() {
        const ref = this.feedbackService.openDialogFromTask(this.notification.task);
        ref.afterClosed()
            .subscribe(() => {
                this.setSeen();
            });
    }

    setSeen($event = null) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }
        this.notificationService.setSeen(this.notification.id).subscribe();
    }
}
