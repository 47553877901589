import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../user/my-profile/change-password-modal/change-password-modal.component';

@Component({
    selector: 'app-before-unload-dialog',
    templateUrl: './before-unload-dialog.component.html',
    styleUrls: ['./before-unload-dialog.component.scss']
})
export class BeforeUnloadDialogComponent {

    private dontSaveForm = true;
    private saveForm = false;

    constructor(private dialogRef: MatDialogRef<BeforeUnloadDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    onNoClick() {
        this.dialogRef.close(this.dontSaveForm);
    }

    onYesClick() {
        this.dialogRef.close(this.saveForm);
    }
}
