<mat-form-field appearance="outline" [subscriptSizing]="subscriptSizing">
    <mat-label>{{label}}</mat-label>
    <label>
        <input type="text"
               matInput
               [readonly]="readonly"
               [formControl]="innerFormControl"
               [matAutocomplete]="auto"
               [matAutocompleteDisabled]="readonly"
               (input)="filter()"
               (blur)="onBlur()"
               [required]="requiredNew">
    </label>
    <mat-autocomplete #auto="matAutocomplete"
                      [displayWith]="displayFn"
                      (optionSelected)="optionSelected()"
                      (opened)="autocompleteIsOpened()"
                      (closed)="autocompleteIsClosed()">
        <mat-option *ngFor="let value of searchResults" [value]="value">
            {{buildDisplayString(value)}}
        </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix>arrow_drop_down</mat-icon>
    <mat-error *ngIf="innerFormControl.invalid">{{ getErrorMessageForFormControl ? getErrorMessageForFormControl(innerFormControl) : '' }}</mat-error>
    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
</mat-form-field>
