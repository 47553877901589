import {Entity} from '../entity.model';
import {TaskType} from './task-type.model';
import {AbstractControl} from '@angular/forms';
import {AdvertisingSpot} from '../advertising-spot/advertising-spot.model';
import {AdvertisingMaterial} from '../advertising-material/advertising-material.model';

export class TaskData extends Entity {
    priority: boolean;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    deadline: Date;
    estimatedEffortInHours: number;
    status: string;
    type: string;
    kind: string;
    trips: string;
    tripServiceProvider: string;
    tripResponsibleUsers: string;
    tripCodes: string;
    tripDateStarts: Date[];
    tripDateEnds: Date[];
    tripExistsInTm: string;
    insertions: string;
    advertisingMaterials: string;
    responsibleUserOrTeam: string;
    createdBy: string;
    advertisingTaskType: TaskType;
    advSpot: AdvertisingSpot;
    advMat: AdvertisingMaterial;
    statusFormControl: AbstractControl;
}
