import {IFilterable} from '../interfaces/filter';
import {IDeserializable} from '../interfaces/deserializable';

export class UserFilter implements IDeserializable, IFilterable<void> {
    enabled: string[];
    name: string[];
    description: string[];
    eMailAddress: string[];
    teams: string[];

    toQuery(...args: any[]): void {
        return;
    }

    deserialize(input: any): this {
        Object.assign(this as any, input);

        this.enabled = input.enabled?.map(b => b ? 'Aktiv' : 'Inaktiv');

        return this;
    }
}
