<h1 mat-dialog-title>Passwort ändern</h1>
<div mat-dialog-content>
    <mat-form-field appearance="outline">
        <mat-label>Altes Passwort</mat-label>
        <input required
               matInput
               type="password"
               [formControl]="oldPassword">
        <mat-error *ngIf="oldPassword.invalid">{{ getErrorMessage(oldPassword) }}</mat-error>
    </mat-form-field>
    <mat-slide-toggle
        class="example-margin"
        [(ngModel)]="showCriteria">
        Kriterien anzeigen
    </mat-slide-toggle>
    <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Neues Passwort</mat-label>
        <input matInput
               required
               type="password"
               placeholder="Neues Passwort"
               [formControl]="newPassword">
        <mat-hint align="end" aria-live="polite">
            {{newPassword.value.length}} / {{minimumPasswordLength}}
        </mat-hint>
        <mat-error *ngIf="newPassword.invalid">{{ getErrorMessage(newPassword) }}</mat-error>
    </mat-form-field>
    <!--@angular-material-extensions/password-strength's main component-->
    <mat-password-strength #passwordComponent
                           (onStrengthChanged)="onStrengthChanged($event)"
                           [password]="newPassword.value"
                           [enableUpperCaseLetterRule]="false"
                           [enableLowerCaseLetterRule]="false">
    </mat-password-strength>
    <!--Password's strength info-->
    <mat-password-strength-info
        *ngIf="showCriteria"
        [passwordComponent]="passwordComponent"
        digitsCriteriaMsg="enthält mindestens eine Ziffer"
        specialCharsCriteriaMsg="enthält mindestens ein Sonderzeichen"
        minCharsCriteriaMsg="enthält mindestens {{minimumPasswordLength}} Zeichen">
    </mat-password-strength-info>

    <div class="password-repeat-field">
        <mat-form-field appearance="outline">
            <mat-label>Passwort wiederholen</mat-label>
            <input required
                   matInput
                   type="password"
                   [formControl]="newPasswordRepeat">
            <mat-error *ngIf="newPasswordRepeat.invalid">{{ getErrorMessageForNotMatch() }}</mat-error>
        </mat-form-field>
    </div>

</div>
<div mat-dialog-actions>
    <button color="primary" mat-button (click)="onNoClick()">Abbrechen</button>
    <button color="primary" mat-raised-button [disabled]="(!changePasswordForm.touched) || (!changePasswordForm.valid)" (click)="onOk()">Passwort ändern</button>
</div>
