import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MainComponent} from './main/main.component';
import {GlobalModule} from '../global/global.module';

@NgModule({
    declarations: [MainComponent],
    imports: [
        CommonModule,
        GlobalModule,
        RouterModule
    ]
})
export class MainModule {
}
