import {Component, Input} from '@angular/core';
import {UserService} from '../../../../service/user/user.service';
import {TaskComment} from '../../../../models/notification/task-comment.model';

@Component({
    selector: 'app-comment-item',
    templateUrl: './comment-item.component.html',
    styleUrls: ['./comment-item.component.scss']
})
export class CommentItemComponent {
    @Input() comment: TaskComment;

    constructor(private userService: UserService) {
    }

    getAvatar(): string {
        return this.userService.getImage(this.comment.createdBy?.avatarImagePath);
    }
}
