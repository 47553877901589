import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {BeforeUnloadDialogComponent} from '../app/global/dialogs/before-unload-dialog/before-unload-dialog.component';

/**
 * @todo explain, naming is confusing
 */
export interface CanDeactivateGuardInterface {
    isFormDirty: () => boolean;
}

export interface CanDeactivateChildFormInterface {
    isFormDirty(): boolean;
    /**
     * @todo refactoring - rename, 'reset' implies values being reset but all implementations of this just mark
     *      controls as pristine.
     */
    resetForm(): void;
}

@Injectable()
export class CanDeactivateGuard  {

    constructor(public dialog: MatDialog) {
    }

    canDeactivate(component: CanDeactivateGuardInterface,
                  route: ActivatedRouteSnapshot,
                  state: RouterStateSnapshot,
                  nextState: RouterStateSnapshot) {

        if (component.isFormDirty()) {
            const dialogRef = this.dialog.open(BeforeUnloadDialogComponent);
            return dialogRef.afterClosed();
        } else {
            return true;
        }
    }
}
