import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login.component';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {BrowserModule} from '@angular/platform-browser';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {SetPasswordComponent} from './set-password/set-password.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {MatPasswordStrengthModule} from '@angular-material-extensions/password-strength';

@NgModule({
    declarations: [
        LoginComponent,
        SetPasswordComponent,
        ForgotPasswordComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        ReactiveFormsModule,
        MatCardModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        RouterModule,
        MatPasswordStrengthModule,
        MatSlideToggleModule,
        FormsModule
    ],
    exports: [
        LoginComponent,
        SetPasswordComponent,
        ForgotPasswordComponent,
    ]
})
export class LoginModule {
}
