import {Router} from '@angular/router';
import {Component} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {HttpStatusCode} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../service/user/user.service';
import {SnackbarConfig} from '../../constants/snackbar.constants';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    loginForm;
    email = new UntypedFormControl('', [Validators.required, Validators.email]);
    password = new UntypedFormControl('', [Validators.required]);
    invalidPassword = false;

    constructor(private userService: UserService,
                private snackBar: MatSnackBar,
                formBuilder: UntypedFormBuilder,
                private router: Router,
                private cookieService: CookieService) {
        this.loginForm = formBuilder.group({
            email: this.email,
            password: this.password
        });
    }

    onSubmit(loginValue) {
        if (this.loginForm.valid) {
            this.userService.login(loginValue.email, loginValue.password)
                .subscribe({
                    next: (response: any) => {
                        const nameKey = 'name';
                        const idKey = 'id';
                        this.cookieService.set(response.body[nameKey], response.body[idKey], 0, '/');
                        this.router.navigate(['/app']).then();
                        this.snackBar.open(
                            'Sie wurden erfolgreich eingeloggt',
                            '',
                            SnackbarConfig.success);
                    },
                    error: (error: any) => {
                        if (error.status === HttpStatusCode.Forbidden) {
                            this.snackBar.open(
                                'Falsche E-Mail oder Passwort',
                                '',
                                SnackbarConfig.error);
                            this.invalidPassword = true;
                        }
                    }
                });
        }
    }

    getErrorMessageForEmail() {
        return this.email.hasError('required') ? 'Feld darf nicht leer sein' :
            this.email.hasError('email') ? 'Keine valide E-Mail-Adresse' :
                '';
    }

    getErrorMessageForPassword() {
        return this.password.hasError('required') ? 'Feld darf nicht leer sein' :
            '';
    }
}
