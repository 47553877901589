import {Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../service/user/user.service';
import {AppRoutes} from '../constants/navigation.constants';
import {SnackbarConfig} from '../constants/snackbar.constants';
import {LoadingService} from '../service/local/loading/loading.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ClaimGuard  {

    constructor(private userService: UserService,
                private loadingService: LoadingService,
                private snackBar: MatSnackBar,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.userService.checkClaim(route.data)
            .pipe(mergeMap(hasClaim => {
                if (hasClaim) {
                    return of(true);
                }
                this.snackBar.open(
                    'Sie haben keine Berechtigungen für diesen Bereich',
                    '',
                    SnackbarConfig.error
                );
                return of(this.router.parseUrl(AppRoutes.app.fullPath));
            }));

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this.userService.getClaims()
            .pipe(
                mergeMap(claims => {
                    if (claims) {
                        return of(claims);
                    } else {
                        return of(this.router.parseUrl(AppRoutes.app.login.fullPath));
                    }
                })
            );
    }
}
