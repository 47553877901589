import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CrudService} from '../crud.service';
import {Observable, ReplaySubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UserService} from '../user/user.service';
import {environment} from '../../environments/environment';
import {Notification} from '../../models/notification/notification.model';
import {NotificationEvent} from '../../models/notification/notification.event';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends CrudService<Notification> {
    public notificationEventSubject = new ReplaySubject<NotificationEvent>();
    public notificationEvents: Observable<NotificationEvent> = this.notificationEventSubject.asObservable();
    private eventSource: EventSource;
    private currentUserId: number;

    constructor(http: HttpClient,
                private userService: UserService) {
        super(http, `${environment.backendUrl}/notifications`);

        this.userService.currentUser.subscribe(user => {
            if (user && this.currentUserId !== user.id) {
                this.currentUserId = user.id;

                if (this.eventSource) {
                    this.eventSource.close();
                }

                if (!environment.eventSourceUrl) {
                    throw new Error('No eventSourceUrl for mercure provided');
                }

                this.eventSource = new EventSource(
                    `${environment.eventSourceUrl}?topic=${
                        encodeURIComponent(`${environment.backendUrl}/notifications/user/${user.id}`)}`
                );

                this.eventSource.onmessage = (messageEvent: MessageEvent) => {
                    const notification = Object.assign(new Notification(), JSON.parse(messageEvent.data));
                    this.generateAddEvent(notification);

                };
                this.eventSource.addEventListener('delete', (messageEvent: MessageEvent) => {
                    const id = JSON.parse(messageEvent.data) as number;
                    this.generateDeleteEvent(id);
                });
            }
        });
    }

    public setSeen(id: number) {
        return this.http.get(`${this.route}/${id}/seen`).pipe(
            tap(() => this.generateDeleteEvent(id))
        );
    }

    protected newInstance(obj: any): Notification {
        return new Notification().deserialize(obj);
    }

    private generateDeleteEvent(id: number) {
        const event = new NotificationEvent();
        event.action = 'delete';
        event.notificationId = id;

        this.notificationEventSubject.next(event);
    }

    private generateAddEvent(notification: Notification) {
        const event = new NotificationEvent();
        event.action = 'add';
        event.notificationId = notification.id;
        event.notification = notification;

        this.notificationEventSubject.next(event);
    }
}
