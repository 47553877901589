import {A11yModule} from '@angular/cdk/a11y';
import {AppComponent} from './app.component';
import {MainModule} from './main/main.module';
import {CdkColumnDef} from '@angular/cdk/table';
import {LoginModule} from './login/login.module';
import {CookieService} from 'ngx-cookie-service';
import localeDe from '@angular/common/locales/de';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {BrowserModule} from '@angular/platform-browser';
import {MatButtonModule} from '@angular/material/button';
import {TaskConstants} from '../constants/task.constants';
import {Injector, LOCALE_ID, NgModule} from '@angular/core';
import localeDeExtra from '@angular/common/locales/extra/de';
import {DatePipe, registerLocaleData} from '@angular/common';
import {CanDeactivateGuard} from '../guard/can-deactivate.guard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MatMomentDateModule,
    MomentDateAdapter
} from '@angular/material-moment-adapter';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export const LISTA_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AppRoutingModule,
        A11yModule,
        MainModule,
        LoginModule,
        MatMomentDateModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatButtonModule
    ],
    providers: [
        CookieService,
        CdkColumnDef,
        DatePipe,
        CanDeactivateGuard,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: LOCALE_ID, useValue: 'de-DE'},
        // nicht entfernen, siehe Ticket #247
        {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
        {provide: MAT_DATE_FORMATS, useValue: LISTA_FORMATS},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private injector: Injector) {
        TaskConstants.injector = this.injector;
    }
}
