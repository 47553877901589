import {ListView} from '../models/list-view/list-view.model';

export class TripConstants {

    public static readonly serviceProviderHrw = 627;
    public static readonly shipTripTypes = ['Hochsee', 'Fluss', 'Rad / Schiff'];

    public static readonly editableStatuses = ['Idee', 'Einkauf'];

    public static readonly tableHeaderValues = [
        'id', 'enabled', 'status', 'category', 'startDate', 'endDate', 'title', 'fromCity', 'toCity',
        'code', 'tripSystem', 'paymentMethod', 'destinationAreas', 'ship', 'nextOptionDate',
        'contingentMin', 'contingentMax', 'nextPublicationDate', 'busLT', 'trainLT',
        'planeLT', 'shipLT', 'travelCompanion', 'serviceProvider', 'responsibleUser',
        'organizerNumber', 'tags', 'hasTravelCompanionOption', 'serialAppointment', 'actions'
    ];

    public static readonly columnTitleMap = {
        id: 'Lista ID',
        status: 'Status',
        category: 'Kategorie',
        code: 'Reise-Code',
        tripSystem: 'System / Buchungsweg',
        paymentMethod: 'Zahlart',
        startDate: 'Start',
        endDate: 'Ende',
        title: 'Titel',
        fromCity: 'Von',
        toCity: 'Bis',
        destinationAreas: 'Zielgebiete',
        ship: 'Schiff Name',
        nextOptionDate: 'Optionen',
        contingentMin: 'MTZ-Min',
        contingentMax: 'MTZ-Max',
        advertisement: 'Werbung',
        shipLT: 'Schiff LT',
        busLT: 'Bus LT',
        planeLT: 'Flugzeug LT',
        trainLT: 'Bahn LT',
        travelCompanion: 'Reisebegleiter LT',
        serviceProvider: 'VA',
        responsibleUser: 'PM',
        enabled: 'Aktiv/Inaktiv',
        tags: 'Tags',
        organizerNumber: 'Gruppennummer',
        nextPublicationDate: 'Werbung',
        hasTravelCompanionOption: 'RB',
        serialAppointment: 'Serientermin'
    };

    public static readonly columnFilterMap = {
        enabled: 'enabled',
        status: 'tripStatus|name',
        category: 'tripType|name',
        code: 'code',
        tripSystem: 'tripSystem',
        paymentMethod: 'paymentMethod',
        startDate: 'startDate',
        endDate: 'endDate',
        title: 'title',
        fromCity: 'fromCity|name',
        toCity: 'toCity|name',
        destinationAreas: 'destinationAreas|name',
        ship: 'ship|name',
        nextOptionDate: 'nextOptionDate',
        contingentMin: 'contingentMin',
        contingentMax: 'contingentMax',
        advertisement: 'advertisement',
        shipLT: 'serviceProviderOptionShip',
        busLT: 'serviceProviderOptionBus',
        planeLT: 'serviceProviderOptionPlane',
        trainLT: 'serviceProviderOptionTrain',
        travelCompanion: 'travelCompanionFullName',
        serviceProvider: 'serviceProvider|ltCode',
        responsibleUser: 'responsibleUserFullName',
        tags: 'tripTag|name',
        organizerNumber: 'organizerNumber',
        nextPublicationDate: 'nextPublicationDate',
        hasTravelCompanionOption: 'hasTravelCompanionOption',
        serialAppointment: 'serialAppointment'
    };

    public static readonly defaultDisplayedValues = [
        'id', 'status', 'category', 'title', 'startDate', 'endDate',
        'code', 'tripSystem', 'destinationAreas', 'ship', 'nextOptionDate',
        'contingentMin', 'contingentMax', 'nextPublicationDate', 'shipLT', 'busLT', 'trainLT',
        'planeLT', 'travelCompanion', 'serviceProvider', 'responsibleUser',
        'organizerNumber', 'actions'
    ];

    public static readonly defaultFilter = {
        status: ['Idee', 'Einkauf', 'Verkauf', 'Abgestimmt', 'Absage', 'Reiseanlage'],
        enabled: ['Aktiv']
    };

    public static readonly defaultListView: ListView = new ListView().deserialize(
        {
            id: null,
            isPersonal: null,
            createdBy: null,
            teams: null,
            activeUsers: null,
            list: 'Reisen-Liste',
            name: 'Standard',
            undeletable: false,
            value: JSON.stringify({
                columns: TripConstants.defaultDisplayedValues,
                filter: TripConstants.defaultFilter
            })
        }
    );
}
