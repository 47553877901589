/**
 * Base class for creating query params for requests.
 */
export class Query {
    limit?: number;
    offset?: number;
    orderBy?: string;
    asc?: boolean;
    search?: string;
    enabled?: number[];

    encodeQueryParams(): Record<string, unknown> {
        const encodedQueryParams: Record<string, unknown> = {};
        for (const [key, param] of Object.entries(this)) {
            if (Array.isArray(param)) {
                encodedQueryParams[key] = param.map(s => encodeURIComponent(s));
            } else {
                if (typeof param === 'string') {
                    encodedQueryParams[key] = encodeURIComponent(param);
                } else {
                    encodedQueryParams[key] = param;
                }
            }
        }
        return encodedQueryParams;
    }

    toQueryString(): string {
        let query = '';

        const queryParams = this.encodeQueryParams();

        if (queryParams.limit) {
            query += `limit=${queryParams.limit}&`;
        }
        if (queryParams.offset) {
            query += `offset=${queryParams.offset}&`;
        }
        if (queryParams.orderBy) {
            query += `orderBy=${(queryParams.asc ? '+' : '-')}${queryParams.orderBy}&`;
        }
        if (queryParams.search) {
            query += `search=${queryParams.search}&`;
        }
        if (queryParams.enabled) {
            query += `enabled=${(queryParams.enabled as number[]).join(',')}&`;
        }
        return query.slice(0, -1);
    }
}
