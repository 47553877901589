import {Entity} from '../entity.model';
import {Ship} from '../ship/ship.model';
import {User} from '../user/user.model';
import {TripType} from './trip-type.model';
import {TripCity} from './trip-city.model';
import {TripStatus} from './trip-status.model';
import {ServiceProvider} from '../service-provider/service-provider.model';
import {ServiceProviderOption} from '../service-provider-option/service-provider-option.model';
import { PaymentMethod } from './trip.model';

export class TripData extends Entity {
    startDate: Date;
    endDate: Date;
    nextOptionDate: string;
    title: string;
    code: string;
    tripSystem: string;
    organizerNumber: string;
    min: number;
    max: number;
    depositInPercent: number;
    daysPriorForFinalpayment: number;
    note: string;
    tripStatus: TripStatus;
    tripType: TripType;
    paymentMethod: PaymentMethod;
    ship: Ship;
    toCity: TripCity;
    fromCity: TripCity;
    nextPublicationDate: string;
    advertisingSpotCount: number;
    ships: string[];
    buses: string[];
    trains: string[];
    planes: string[];
    travelCompanions: string[];
    serviceProvider: ServiceProvider;
    destinationAreas: string[];
    serviceProviderOption: ServiceProviderOption;
    responsibleUser: User;
    enabled: boolean;
    serialAppointment: boolean;
    tags: string[];
    hasTravelCompanionOption: boolean;
    old: boolean;
}
