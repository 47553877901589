import {Entity} from '../entity.model';
import {User} from '../user/user.model';
import {Task} from '../task/task.model';

export class TaskComment extends Entity {
    public text: string;
    public createdAt: Date;
    public task: Task;
    public createdBy: User;
}
