export class ClaimsConstants {
    public static readonly sectionNames = {
        administration: 'Administration',
        coreData: 'Stammdaten',
        media: 'Medium',
        format: 'Format',
        trip: 'Reise',
        tripMarketing: 'Reise-Marketing',
        insertion: 'Insertion',
        advertisingMaterial: 'Werbemittel',
        tasks: 'Aufgaben',
        travelCompanion: 'Reisebegleiter',
        bookAdvertisement: 'Anzeigen-Platz-reservieren',
        payedAdvertisementsTripsMapping: 'Bezahlte-Anzeigen-Reisen-mappen',
        adaptAdvertisementTripType: 'Anzeigen-Platz-Reisetyp-anpassen',
        advertisingSpot: 'Werbeplätze',
        review: 'Review-durchfuehren',
        productManager: 'Auswaehlbar als Produktmanager',
        admin: 'Admin'
    };

    public static readonly adminClaim = {
        claim: ClaimsConstants.sectionNames.admin
    };
}
