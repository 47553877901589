<div class="topbar mat-elevation-z8">
    <div class="logo">
        <img src="app/assets/lista-logo-topbar.svg" alt="Lista Logo">
    </div>

    <div class="menu">
        <app-top-menu></app-top-menu>
    </div>

    <div class="controls">

        <!--
        <app-searchbar></app-searchbar>
        -->

        <div class="icon_wrapper">
            <a [routerLink]="routes.app.tasks.new.fullPath">
                <fa-icon
                    class="topbar_icon"
                    [icon]="faPlus"
                    size="lg"
                ></fa-icon>
            </a>
        </div>

        <app-notification-bell></app-notification-bell>

        <app-user></app-user>

    </div>
</div>
