import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../../../service/user/user.service';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarConfig} from '../../../../constants/snackbar.constants';
import {mustMatch} from '../../../global/validation/mustmatch.validator';
import {AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {ErrorResponseMessages} from '../../../../constants/error-response-messages.constants';

export class DialogData {
    oldPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
}

@Component({
    selector: 'app-change-password-modal',
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit, AfterViewChecked {
    changePasswordForm;
    oldPassword = new UntypedFormControl('', [Validators.required]);
    newPassword = new UntypedFormControl('');
    newPasswordRepeat = new UntypedFormControl('', [Validators.required]);
    showCriteria: boolean;
    minStrength = 100;
    minimumPasswordLength = 8;

    constructor(private formBuilder: UntypedFormBuilder,
                public dialogRef: MatDialogRef<ChangePasswordModalComponent>,
                private userService: UserService,
                private snackBar: MatSnackBar,
                private cdRef: ChangeDetectorRef,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    ngOnInit() {
        this.changePasswordForm = this.formBuilder.group({
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
                newPasswordRepeat: this.newPasswordRepeat
            },
            {
                validators: [mustMatch(this.newPassword, this.newPasswordRepeat)]
            });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onOk() {
        if (this.changePasswordForm.valid) {
            this.userService.changePassword(
                this.oldPassword.value,
                this.newPassword.value,
                this.newPasswordRepeat.value
            ).subscribe({
                next: () => {
                    this.snackBar.open(
                        'Passwort geändert',
                        '',
                        SnackbarConfig.success);
                    this.dialogRef.close();
                },
                error: error => {
                    if (error.error.errors.includes(ErrorResponseMessages.oldPasswordIsWrong)) {
                        this.snackBar.open(
                            'Das alte Passwort ist falsch',
                            '',
                            SnackbarConfig.error
                        );
                    }
                }
            });

        }
    }

    onStrengthChanged(strength: number) {
        if (strength < this.minStrength) {
            this.newPassword.setErrors({unsafe: true, error: 'unsafe'});
        } else {
            this.newPassword.setErrors(null);
        }
    }

    getErrorMessage(formControl: UntypedFormControl): string {
        if (formControl.hasError('unsafe')) {
            return 'Passwortsicherheit ungenügend';
        }
        if (formControl.hasError('required')) {
            return 'Feld darf nicht leer sein';
        }
    }

    getErrorMessageForNotMatch(): string {
        return 'Passwörter müssen übereinstimmen';
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
}
