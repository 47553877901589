import {Entity} from '../entity.model';
import {Team} from '../team/team.model';
import {User} from '../user/user.model';
import {ListViewData} from './list-view-data.model';
import {IConvertable} from '../interfaces/convertable';
import {ListName} from '../../custom-types/list-name.type';

export class ListView extends Entity implements IConvertable<ListViewData> {
    name: string;
    isPersonal: boolean;
    createdBy: User;
    list: ListName;
    value: string;
    teams: Team[];
    activeUsers: User[];
    undeletable: boolean;

    convert(): ListViewData {
        return new ListViewData().deserialize(
            {
                id: this.id,
                name: this.name,
                creatorFullName: this.createdBy.firstName + ' ' + this.createdBy.lastName,
                list: this.list,
                value: this.value,
                teams: this.teams ? this.teams.map(team => team.name) : []
            }
        );
    }
}
