import {Component} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../../service/user/user.service';
import {SnackbarConfig} from '../../../constants/snackbar.constants';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
    forgotPasswordForm: UntypedFormGroup;
    /** Toggle for custom validation of the email address. */
    invalidUntilChanged = false;

    constructor(
        private userService: UserService,
        private snackBar: MatSnackBar
    ) {
        // a custom validator to mark the current email as invalid after a request failed
        const invalidUntilChangedValidator = () => this.invalidUntilChanged ? { invalidUntilChanged: true } : null;
        this.forgotPasswordForm = new UntypedFormGroup({
            email: new UntypedFormControl(
                '',
                [Validators.required, Validators.email, invalidUntilChangedValidator]
            )
        });
    }

    /**
     * Updates the invalidUntilChanged validator when the email value changes.
     */
    onEmailChange() {
        this.invalidUntilChanged = false;
        this.forgotPasswordForm.get('email').updateValueAndValidity();
    }

    onSubmit() {
        if (!this.forgotPasswordForm.valid) {
            return;
        }

        // lock form while submitting
        this.forgotPasswordForm.disable();

        this.userService.requestPassword(this.forgotPasswordForm.controls.email.value as string)
            .subscribe({
                next: () => {
                    // Note: form is not re-enabled on success
                    this.snackBar.open(
                        'Ihnen wurde eine E-Mail zum Zurücksetzen Ihres Passworts zugeschickt',
                        '',
                        SnackbarConfig.success
                    );
                },
                error: (error: HttpErrorResponse) => {
                    /* invalidate email field to force user to change their input */
                    this.invalidUntilChanged = true;
                    this.forgotPasswordForm.get('email').updateValueAndValidity();
                    this.forgotPasswordForm.enable();

                    this.snackBar.open(error.error.message ?? error.message, '', SnackbarConfig.success);
                    throw error;
                }
            });
    }
}
