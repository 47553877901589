import {User} from '../models/user/user.model';
import {ClaimsConstants} from '../constants/claims.constants';

export class UserHelper {
    public static getFullName(user: User): string {
        return user ? `${user.firstName} ${user.lastName}` : null;
    }

    public static canBeResponsiblePM(user: User): boolean {
        return user.teams.some(t => t.privileges.some(p => p.sectionName === ClaimsConstants.sectionNames.productManager));
    }
}
