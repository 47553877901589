import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {SnackbarConfig} from '../constants/snackbar.constants';
import {MatSnackBar, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpStatusCode
} from '@angular/common/http';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

    private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

    constructor(private router: Router,
                private snackBar: MatSnackBar)
    { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap({
                next: (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        // do stuff with response if you want
                    }
                },
                error: (err: any) => {
                    if (!(err instanceof HttpErrorResponse)) {
                        return;
                    }
                    switch (err.status) {
                        case HttpStatusCode.Unauthorized:
                            this.handleUnAuthorizedError(request);
                            return;
                        case HttpStatusCode.RequestHeaderFieldsTooLarge:
                            this.handleRequestHeaderFieldsTooLargeError();
                            return;
                        default:
                            this.handleError(err);
                    }
                }
            })
        );
    }

    private handleUnAuthorizedError(request: HttpRequest<any>): void {
        /* ignore password reset request 401 error here because it's handled locally */
        if (request.url === `${environment.backendUrl}/users/request-password` && request.method === 'POST') {
            return;
        }

        this.router.navigate(['app/login']).then();
        if (this.snackBarRef) {
            return;
        }
        this.snackBarRef = this.snackBar.open(
            'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
            '',
            SnackbarConfig.error);
        this.snackBarRef.afterDismissed().subscribe(() => {
            this.snackBarRef = null;
        });
    }

    private handleRequestHeaderFieldsTooLargeError(): void {
        if (this.snackBarRef) {
            return;
        }
        this.snackBarRef = this.snackBar.open(
            'Filterung fehlgeschlagen: Zu viele Werte in der Auswahl.',
            '',
            SnackbarConfig.error);
        this.snackBarRef.afterDismissed().subscribe(() => {
            this.snackBarRef = null;
        });
    }

    private handleError(err: HttpErrorResponse): void {
        if (this.snackBarRef) {
            return;
        }
        const errorText = err.error?.errors?.message ?? 'Es ist ein unbekannter Fehler aufgetreten.';
        this.snackBarRef = this.snackBar.open(
            errorText,
            '',
            SnackbarConfig.error);
        this.snackBarRef.afterDismissed().subscribe(() => {
            this.snackBarRef = null;
        });
    }
}
