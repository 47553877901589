<div>
    <div comments>
        <ng-container *ngIf="comments.length < 1">
            <div empty>
                <h3>Noch keine Kommentare</h3>
            </div>
        </ng-container>
        <ng-container *ngFor="let comment of comments; let i = index">
            <mat-divider *ngIf="i > 0"></mat-divider>
            <app-comment-item [comment]="comment"></app-comment-item>
        </ng-container>
    </div>
    <form [formGroup]="newComment" (ngSubmit)="submit()">
        <div new>
            <mat-form-field appearance="outline">
                <textarea matInput [formControl]="commentText"
                          placeholder="Einen Kommentar schreiben"
                          rows="5"></textarea>
            </mat-form-field>
            <button [disabled]="!(commentText.value?.length > 0)"
                    color="primary"
                    mat-raised-button type="submit">
                Kommentieren
            </button>
        </div>
    </form>
</div>
