import {Menu} from './model/menu.model';
import {AppRoutes} from '../../../../constants/navigation.constants';
import {ClaimsConstants} from '../../../../constants/claims.constants';
import {
    faBuilding,
    faCalendarAlt,
    faCog,
    faListAlt,
    faLocationArrow,
    faMailBulk,
    faPhotoVideo,
    faShip,
    faTable,
    faTag,
    faTasks,
    faUmbrellaBeach,
    faUser,
    faUserCog,
    faUserFriends,
    faUsers
} from '@fortawesome/free-solid-svg-icons';

export class MenuValues {
    static menu: Menu[] = [
        {
            icon: faUmbrellaBeach,
            title: 'Reisen',
            link: AppRoutes.app.trips.fullPath,
            claims: [{claim: ClaimsConstants.sectionNames.trip}]
        },
        {
            icon: faMailBulk,
            title: 'Werbeplanung',
            link: AppRoutes.app.insertions.fullPath,
            claims: [
                {claim: ClaimsConstants.sectionNames.insertion}
            ]
        },
        {
            icon: faCalendarAlt,
            title: 'Werbemittel',
            link: AppRoutes.app.advertisingMaterial.fullPath,
            claims: [{claim: ClaimsConstants.sectionNames.advertisingMaterial}]
        },
        {
            icon: faListAlt,
            title: 'Aufgaben',
            link: AppRoutes.app.tasks.fullPath,
            claims: [{claim: ClaimsConstants.sectionNames.tasks}]
        },
        {
            icon: faUser,
            title: 'Benutzer',
            link: AppRoutes.app.user.fullPath,
            claims: [{claim: ClaimsConstants.sectionNames.administration}],
            subMenus: [
                {
                    icon: faUsers,
                    title: 'Teams',
                    link: AppRoutes.app.user.team.fullPath,
                    claim: {claim: ClaimsConstants.sectionNames.administration}
                },
                {
                    icon: faUserCog,
                    title: 'Benutzerverwaltung',
                    link: AppRoutes.app.user.fullPath,
                    claim: {claim: ClaimsConstants.sectionNames.administration}
                }
            ]
        },
        {
            icon: faCog,
            title: 'Einstellungen',
            link: AppRoutes.app.settings.fullPath,
            claims: [
                {claim: ClaimsConstants.sectionNames.format},
                {claim: ClaimsConstants.sectionNames.media},
                {claim: ClaimsConstants.sectionNames.travelCompanion},
                {claim: ClaimsConstants.sectionNames.coreData}
            ],
            subMenus: [
                {
                    icon: faBuilding,
                    title: 'Leistungsträger',
                    link: AppRoutes.app.settings.serviceProvider.fullPath,
                    claim: {claim: ClaimsConstants.sectionNames.coreData}
                },
                {
                    icon: faPhotoVideo,
                    title: 'Formate',
                    link: '/app/settings/formats',
                    claim: {claim: ClaimsConstants.sectionNames.format}
                },
                {
                    icon: faPhotoVideo,
                    title: 'Medien',
                    link: '/app/settings/media',
                    claim: {claim: ClaimsConstants.sectionNames.media}
                },
                {
                    icon: faUserFriends,
                    title: 'Reisebegleiter',
                    link: AppRoutes.app.settings.travelCompanions.fullPath,
                    claim: {claim: ClaimsConstants.sectionNames.travelCompanion}
                },
                {
                    icon: faLocationArrow,
                    title: 'Zielgebiete',
                    link: AppRoutes.app.settings.destinationAreas.fullPath,
                    claim: {claim: ClaimsConstants.sectionNames.coreData}
                },
                {
                    icon: faShip,
                    title: 'Schiffe',
                    link: AppRoutes.app.settings.ships.fullPath,
                    claim: {claim: ClaimsConstants.sectionNames.coreData}
                },
                {
                    icon: faTasks,
                    title: 'Aufgabentypen',
                    link: AppRoutes.app.settings.tasks.fullPath,
                    claim: {claim: ClaimsConstants.sectionNames.administration}
                },
                {
                    icon: faTable,
                    title: 'Listen-Ansichten',
                    link: AppRoutes.app.settings.listViews.fullPath,
                    claim: {claim: ClaimsConstants.sectionNames.administration}
                },
                {
                    icon: faTag,
                    title: 'Reise-Tags',
                    link: AppRoutes.app.settings.tags.fullPath,
                    claim: {claim: ClaimsConstants.sectionNames.administration}
                }
            ]
        }
    ];
}
