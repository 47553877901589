import {faBell, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {onlyUniqueEntities} from '../../../../../helper/array.helper';
import {AfterViewInit, ChangeDetectorRef, Component} from '@angular/core';
import {Notification} from '../../../../../models/notification/notification.model';
import {NotificationEvent} from '../../../../../models/notification/notification.event';
import {NotificationService} from '../../../../../service/notification/notification.service';

@Component({
    selector: 'app-notification-bell',
    templateUrl: './notification-bell.component.html',
    styleUrls: ['./notification-bell.component.scss']
})
export class NotificationBellComponent implements AfterViewInit {
    faBell = faBell;
    faEnvelope = faEnvelope;

    notifications: Notification[] = [];

    constructor(private notificationService: NotificationService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    private static compare(a: Notification, b: Notification) {
        if (a.createdAt < b.createdAt) {
            return 1;
        }
        if (a.createdAt === b.createdAt) {
            return 0;
        }
        return -1;
    }

    ngAfterViewInit() {
        this.notificationService.getAll()
            .subscribe(n => {
                this.notifications = n;
                this.notifications?.sort(NotificationBellComponent.compare);
                this.changeDetectorRef.detectChanges();

                this.notificationService.notificationEvents
                    .subscribe((event: NotificationEvent) => {
                        const notifications = this.notifications;
                        switch (event.action) {
                            case 'add':
                                notifications.push(event.notification);
                                this.notifications = notifications.filter(onlyUniqueEntities);
                                break;
                            case 'delete':
                                this.notifications = notifications.filter(notification =>
                                    notification.id !== event.notificationId);
                                break;
                        }
                        this.changeDetectorRef.detectChanges();
                    });
            });
    }
}
