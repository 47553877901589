import * as moment from 'moment';
import {UntypedFormControl, ValidatorFn} from '@angular/forms';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function todayOrFuture(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: boolean } | null => {

        const today = moment().subtract(1, 'days');

        if (today.diff(control.value) >= 0) {
            return {past: true};
        } else {
            return null;
        }
    };
}
