import {Entity} from '../entity.model';

export class UserData extends Entity {
    enabled: boolean;
    avatar: string;
    name: string;
    eMailAddress: string;
    description: string;
    teams: string[];
}
