export class FeedbackConstants {
    public static readonly strings = {
        approveDescription: 'Ich habe keine Änderungswünsche mehr nach dem Review.' +
            ' PDF freigegeben',
        approve: 'Version $n freigeben',
        giveFeedbackDescription: 'Mein Review ist abgeschlossen. ' +
            'Ich habe alle meine Änderungswünsche auf dem PDF kommentiert.',
        giveFeedback: 'Feedback abgegeben und neue Version zum Review erstellen',
        giveFeedbackAndApproveDescription: 'Mein Review ist abgeschlossen. ' +
            'Ich habe alle meine Änderungswünsche auf dem PDF kommentiert. ' +
            'Ich brauche kein weiteres Review. Version freigegeben.',
        giveFeedbackAndApprove: 'Feedback umsetzen und neue Version freigeben',
        askFeedback: {
            1: 'Feedback einholen',
            2: 'Gesamtabnahme beauftragen',
            3: 'Lektorat beauftragen'
        },
        restartReview: 'Review neu starten',
        finishReview: 'Aufgabe auf erledigt setzen'
    };

    public static readonly status = {
        feedback: 3,
        approved: 2,
        inReview: 1,
        inReviewWithNoFurtherFeedback: 4,
        done: 6,
    };

    public static readonly actions = {
        askForFeedback: 'askForFeedback',
        approve: 'approve',
        giveFeedback: 'giveFeedback',
        giveFeedbackAndApprove: 'giveFeedbackAndApprove',
        restartReview: 'restartReview',
        finishReview: 'finishReview'
    };
}
