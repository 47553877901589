<mat-grid-list
    cols="2"
    rowHeight="5rem"
    [gutterSize]="10"
>
    <mat-grid-tile
        colspan="1"
    >
        <div class="open_file ">
            <a [href]="filePath" target="_blank" [class]="attachmentData.new ? 'no-link' : ''">{{fileName}}</a>
        </div>
    </mat-grid-tile>
    <mat-grid-tile
        colspan="1"
    >
        <div class="delete_file">
            <div class="mat-form-field-wrapper">
                <button type="button"
                        mat-icon-button
                        aria-label="Lösche Anlage"
                        (click)="deleteAttachment()">
                    <fa-icon [icon]="faTrash"
                             size="lg"
                    ></fa-icon>
                </button>
            </div>
        </div>
    </mat-grid-tile>
</mat-grid-list>
