import {Entity} from '../entity.model';
import {User} from '../user/user.model';
import {Trip} from '../trip/trip.model';
import {Team} from '../team/team.model';
import {DatePipe} from '@angular/common';
import {UntypedFormGroup} from '@angular/forms';
import {TaskType} from './task-type.model';
import {TaskData} from './task-data.model';
import {Attachment} from './attachment.model';
import {TaskStatus} from './task-status.model';
import {Insertion} from '../insertion/insertion.model';
import {IConvertable} from '../interfaces/convertable';
import {TaskFolderLink} from './task-folder-link.model';
import {Config} from '../../constants/config.constants';
import {TaskConstants} from '../../constants/task.constants';
import {TaskComment} from '../notification/task-comment.model';
import {AdvertisingSpot} from '../advertising-spot/advertising-spot.model';
import {AdvertisingMaterial} from '../advertising-material/advertising-material.model';

export class Task extends Entity implements IConvertable<TaskData> {
    name: string;
    description: string;
    priority: boolean;
    startDate: Date;
    endDate: Date;
    estimatedEffortInHours: number;
    type: TaskType;
    status: TaskStatus;
    createdBy: User;
    responsibleUser: User;
    responsibleTeam: Team;
    trips: Trip[];
    insertions: Insertion[];
    advertisingMaterials: AdvertisingMaterial[];
    advertisingSpot: AdvertisingSpot;
    attachments: Attachment[];
    folderLinks: TaskFolderLink[];
    taskFolderLinks: TaskFolderLink[];
    advertisingTaskType: TaskType;
    taskComments: TaskComment[];
    version: number;
    discriminator?: 'AdvertisingTask' | 'BaseTask';
    editDate: Date;

    deserialize(input: any): this {
        super.deserialize(input);

        if (input?.trips){
            this.trips = input.trips.map(t => new Trip().deserialize(t));
        }

        return this;
    }

    convert(datePipe: DatePipe, statusFormGroup?: UntypedFormGroup): TaskData {
        return new TaskData().deserialize(
            {
                id: this.id,
                priority: this.priority,
                name: this.name,
                description: this.description,
                startDate: this.startDate ? datePipe.transform(this.startDate, Config.dateFormat) : '-',
                endDate: this.endDate ? datePipe.transform(this.endDate, Config.dateFormat) : '-',
                deadline: new Date(this.endDate),
                trips: this.trips && this.trips.length ? this.trips.map(t => t.title).join(', ') : '-',
                tripServiceProvider: this.trips.length ? this.trips.map(t => t.serviceProvider.name).join(', ') : '-',
                tripResponsibleUsers: this.trips.length ? this.trips.map(t => t.responsibleUser.formattedName).join(', ') : '-',
                tripCodes: this.trips && this.trips.length ? this.trips.map(t => t.code).join(', ') : '-',
                tripDateStarts: this.trips && this.trips.length ? this.trips.map(t => new Date(t.startDate)) : null,
                tripDateEnds: this.trips && this.trips.length ? this.trips.map(t => new Date(t.endDate)) : null,
                tripExistsInTm: this.trips && this.trips.length ?
                    this.trips.map(t => t.tripExistsInTm ? 'Ja' : 'Nein').join(', ') : '-',
                insertions:
                    this.insertions && this.insertions.length ? this.insertions.map(i => i.adcode).join(', ') : '-',
                advertisingMaterials: this.advertisingMaterials && this.advertisingMaterials.length
                    ? this.advertisingMaterials.map(a => a.insertion
                        ? a.insertion.adcode : a.advertisingSpot.insertion.adcode).join(', ') : '-',
                responsibleUserOrTeam: this.responsibleUser
                    ? `${this.responsibleUser.firstName} ${this.responsibleUser.lastName}` : (this.responsibleTeam
                        ? `${this.responsibleTeam.name}` : '-'),
                createdBy: `${this.createdBy.firstName} ${this.createdBy.lastName}`,
                estimatedEffortInHours: this.estimatedEffortInHours,
                status: this.status.name,
                type: this.type.name,
                kind: this.type.automatic ? TaskConstants.strings.automatic : TaskConstants.strings.manual,
                advertisingTaskType: this.advertisingTaskType ? this.advertisingTaskType : null,
                advSpot: this.advertisingSpot ? this.advertisingSpot : null,
                advMat: this.advertisingMaterials[0] ? this.advertisingMaterials[0] : null,
                statusFormControl: !this.advertisingTaskType ? statusFormGroup.controls.status : null
            }
        );
    }
}
