import {TaskQuery} from './task-query.model';
import {IFilterable} from '../interfaces/filter';
import {IDeserializable} from '../interfaces/deserializable';
import {toHtmlString} from '../../extensions/functions.helper';

export class TaskFilter implements IDeserializable, IFilterable<TaskQuery> {
    static readonly notAssigned = ' - ohne Zuordnung - ';

    name: string[];
    startDate: Date[];
    endDate: Date[];
    estimatedEffortInHours: number[];
    status: string[];
    automatic: string[];
    type: string[];
    trip: string[];
    tripCode: string[];
    tripServiceProvider: string[];
    tripResponsibleUsers: string[];
    tripDate: Date[];
    tripExistsInTm?: string[];
    responsibleUserOrTeam: string[];
    createdBy: string[];
    insertion: string[];
    advertisingMaterial: string[];
    enabled?: string[];

    deserialize(input: any): this {
        Object.assign(this as any, input);

        return this;
    }

    toFilterBody(filteredCategories: string[]): any {
        const body = {} as any;

        if (filteredCategories.includes('status')) {
            body['status|name'] = this.status.join(',');
        }
        if (filteredCategories.includes('name')) {
            body.name = this.name.join(',');
        }
        if (filteredCategories.includes('type')) {
            body['type|name'] = this.type.join(',');
        }
        if (filteredCategories.includes('trip')) {
            body['trips|title'] = this.trip.map(value => value !== TaskFilter.notAssigned ? value : '').join(',');
        }
        if (filteredCategories.includes('tripCode')) {
            body['trips|code'] = this.tripCode.map(value => value !== TaskFilter.notAssigned ? value : '').join(',');
        }
        if (filteredCategories.includes('tripExistsInTm')) {
            body['trips|tripExistsInTm'] = this.tripExistsInTm.map(b => b === 'Ja' ? 1 : 0).join(',');
        }
        if (filteredCategories.includes('tripServiceProvider')) {
            body['trips|serviceProvider|name'] = this.tripServiceProvider.join(',');
        }
        if (filteredCategories.includes('tripResponsibleUsers')) {
            body.tripResponsibleUsers = this.tripResponsibleUsers.join(',');
        }
        if (filteredCategories.includes('insertion')) {
            body['insertions|adcode'] = this.insertion.map(value => value !== TaskFilter.notAssigned ? value : '').join(',');
        }
        if (filteredCategories.includes('advertisingMaterial')) {
            body.sharedValueAdvertisingMaterialAdcode = this.advertisingMaterial
                .map(value => value !== TaskFilter.notAssigned ? value : '').join(',');
        }
        if (filteredCategories.includes('startDate')) {
            body.startDate = this.startDate.map(date => toHtmlString(date)).join(',');
        }
        if (filteredCategories.includes('endDate')) {
            body.endDate = this.endDate.map(date => toHtmlString(date)).join(',');
        }
        if (filteredCategories.includes('tripDate')) {
            body.tripDate = this.tripDate.map(date => toHtmlString(date)).join(',');
        }
        if (filteredCategories.includes('responsibleUserOrTeam')) {
            body.sharedValueResponsibleUserOrTeam = this.responsibleUserOrTeam
                .map(value => value !== TaskFilter.notAssigned ? value : '').join(',');
        }
        if (filteredCategories.includes('createdBy')) {
            body.createdByFullName = this.createdBy.join(',');
        }
        if (filteredCategories.includes('automatic')) {
            body['type|automatic'] = this.automatic.map(b => b === 'automatisch' ? 1 : 0).join(',');
        }
        return body;
    }

    public toQuery(...args: any[]): TaskQuery {
        return new TaskQuery();
    }
}
