import {Query} from '../query.model';

/**
 * Task-specific implementation for creating query params for requests.
 */
export class TaskQuery extends Query {
    periodFilter?: string;

    toQueryString(): string {
        let query = super.toQueryString();

        if (this.periodFilter) {
            query += `&periodFilter=${this.periodFilter}`;
        }

        return query;
    }
}
