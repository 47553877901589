import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import {LoadingComponent} from '../../../app/global/snackbar/loading/loading.component';
import {SnackbarConfig} from '../../../constants/snackbar.constants';
import {Config} from '../../../constants/config.constants';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    loadingTimer;
    loadingCount = 0;
    snackBarRef: MatSnackBarRef<LoadingComponent>;
    delay = Config.loadingDelay;

    constructor(private loadingSpinner: MatSnackBar,
                private router: Router) {
        this.router.events.subscribe(routingEvent => {
            if (routingEvent instanceof NavigationEnd) {
                this.loadingCount = 1;
                this.clearTimer();
            }
        });
    }

    showSpinner() {
        if (!this.loadingTimer) {
            this.loadingTimer = setTimeout(
                () => {
                    if (!this.snackBarRef) {
                        this.snackBarRef = this.loadingSpinner
                            .openFromComponent(LoadingComponent, SnackbarConfig.loading);
                    }
                },
                this.delay);
        }
        this.loadingCount++;
    }

    hideSpinner() {
        this.loadingCount--;
        if (this.loadingCount <= 0) {
            this.clearTimer();
        }
    }

    private clearTimer() {
        clearTimeout(this.loadingTimer);
        this.loadingTimer = null;
        if (this.snackBarRef) {
            this.snackBarRef.dismiss();
            this.snackBarRef = null;
        }

        this.loadingCount = 0;
    }
}
