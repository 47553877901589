import Fuse from 'fuse.js';
import { UntypedFormControl, Validators } from '@angular/forms';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-autocomplete-select',
    templateUrl: './autocomplete-select.component.html',
    styleUrls: ['./autocomplete-select.component.scss']
})
export class AutocompleteSelectComponent<T extends Record<string, unknown>> implements OnChanges {
    @Output() valueChange = new EventEmitter();
    /** @todo refactoring - use a required validator on the FormControl instead */
    @Input() required?: boolean;
    @Input() readonly: boolean;
    @Input() label: string;
    @Input() allOptions: T[];
    @Input() searchResults: T[] = [];
    @Input() searchKeys: string[] = ['name'];
    @Input() innerFormControl: UntypedFormControl;
    @Input() setValueIfNoResult: boolean;
    /** @todo refactoring - set the FormControl to disabled instead */
    @Input() disabled = false;
    @Input() hint: string;
    @Input() getErrorMessageForFormControl?: (ctrl: UntypedFormControl) => string;
    @Input() subscriptSizing: 'fixed' | 'dynamic';

    initialValue: T | null = null;
    private isOpen: boolean;

    get selectedValue(): T | null {
        return this.innerFormControl?.value ?? null;
    }

    /** Temporary solution to move away from 'required' input towards FormControl required validator. */
    get requiredNew(): boolean {
        return this.required ?? this.innerFormControl.hasValidator(Validators.required);
    }

    @Input() buildDisplayString: (option: T) => string =
        (option: T) => typeof option === 'string' ? option : option.name as string;

    ngOnChanges(changes: SimpleChanges): void {
        this.initialValue = this.innerFormControl.value;

        if (Array.isArray(this.initialValue)) {
            this.innerFormControl.setValue(this.innerFormControl.value?.[0]);
        }
        if (this.allOptions) {
            this.searchResults = this.allOptions;
        }
        if (changes.disabled) {
            if (this.disabled) {
                this.innerFormControl.disable({emitEvent: false});
            } else {
                this.innerFormControl.enable({emitEvent: false});
            }
        }
    }

    displayFn: (option?: any) => string | undefined = (option?: any):
        string | undefined => option ? this.buildDisplayString(option) : undefined;

    /**
     * @todo refactoring - debounce input to avoid filtering too often
     */
    filter() {
        if (this.allOptions) {
            if (this.innerFormControl.value) {
                const options = {keys: this.searchKeys};
                const search = new Fuse(this.allOptions, options);
                const pattern = this.innerFormControl.value as string;

                const resultObjects = [];
                const itemKey = 'item';
                search.search(pattern).forEach(result => resultObjects.push(result[itemKey]));
                this.searchResults = resultObjects;
                if (!(this.searchResults.length > 0)) {
                    this.isOpen = false;
                }
            } else {
                this.searchResults = this.allOptions;
            }
        }
    }

    onBlur() {
        if (!this.allOptions || (this.allOptions && !this.isOpen)) {
            this.checkIfValueIsValid();
        }
    }

    autocompleteIsOpened() {
        this.isOpen = true;
    }

    optionSelected() {
        this.valueChange.emit(this.innerFormControl.value);
    }

    autocompleteIsClosed() {
        this.isOpen = false;
        this.onBlur();

        if (!this.selectedValue) {
            this.innerFormControl.setValue(this.initialValue);
        }
        this.valueChange.emit(this.innerFormControl.value);
    }

    private checkIfValueIsValid(): void {
        const fcValue = this.innerFormControl.value;
        if (this.searchResults && !this.searchResults.includes(fcValue) && this.selectedValue) {
            if (!this.searchResults.map(option => this.buildDisplayString(option)).includes(fcValue)) {
                /* Für den Fall, dass Input gebrauch wird
                 * (siehe https://app.asana.com/0/1134827984845497/1142348934021897)
                 */
                if (!this.setValueIfNoResult) {
                    this.innerFormControl.setValue(undefined);
                }
                if (this.allOptions) {
                    this.searchResults = this.allOptions;
                }
            } else {
                this.innerFormControl.setValue(this.searchResults.find(
                    option => this.buildDisplayString(option).includes(fcValue)));
            }
        }
    }
}
