import {UntypedFormControl} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {Attachment} from '../../../../models/task/attachment.model';
import {AttachmentData} from '../../../../models/task/attachment-data.model';
import {faCloudDownloadAlt, faTrash} from '@fortawesome/free-solid-svg-icons';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-task-attachment',
    templateUrl: './task-attachment.component.html',
    styleUrls: ['./task-attachment.component.scss']
})
export class TaskAttachmentComponent implements OnChanges {
    @Input() control: UntypedFormControl;
    @Input() attachmentData: AttachmentData;
    @Input() attachmentIndex: number;
    @Input() deletedFC: UntypedFormControl;

    faTrash = faTrash;
    faCloudDownloadAlt = faCloudDownloadAlt;

    fileName;
    filePath = environment.uploadUrl;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.attachmentData.currentValue) {
            const attachment: AttachmentData = changes.attachmentData.currentValue;
            if (attachment.new) {
                const file: File = attachment.file;
                this.fileName = `${file.name} (Neu hinzugefügt)`;
            } else {
                const file: Attachment = attachment.file;
                this.fileName = file.originalName;
                this.filePath += `/${file.pathToFile}`;
            }
        }
    }

    deleteAttachment(): void {
        const removedAttachment = this.control.value.splice(this.attachmentIndex, 1);
        this.control.markAsDirty();
        this.deletedFC.value.push(removedAttachment[0].file);
        this.deletedFC.markAsDirty();
    }
}
